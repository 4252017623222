import {Component, OnChanges, OnInit, SimpleChange, SimpleChanges} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'frontend';

  appMode: string = 'LOADING';

  constructor(private router: Router) {
    router.events.subscribe((val) => {
      // see also
      if(val instanceof NavigationEnd) {
        const pathname = document.location.pathname;
        if (pathname.startsWith('/signin') || pathname.startsWith('/signup')) {
          this.appMode = 'CLEANPAGE';
        } else if (pathname.startsWith('/')) {
          this.appMode = 'ADMINMENU';
        }
      }
    });
  }

  ngOnInit() {}

}
