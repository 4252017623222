import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FillDataTable, TableRowFillData} from "../../../interfaces/FillData";

@Component({
  selector: 'app-table-filler',
  templateUrl: './table-filler.component.html',
  styleUrls: ['./table-filler.component.scss']
})
export class TableFillerComponent implements OnInit {

  @Input() model: FillDataTable;
  @Output() modelChanged = new EventEmitter<FillDataTable>();

  editCell: string;

  constructor() {
  }

  ngOnInit(): void {
    // this.model.body = [
    //   Array.from({length: this.model.head.length}, (_, i) => i),
    //   Array.from({length: this.model.head.length}, (_, i) => i),
    // ]
  }

  private updateModel() {
    // do something
    this.modelChanged.emit(this.model);
  }

  onAddRow() {
    this.modelChanged.emit(this.model);
    this.model.body.push(Array.from({length: this.model.head.length}, () => ""))
  }

  onDeleteRow(row: TableRowFillData) {
    if (!confirm("Удалить строку с даными?")) {
      return;
    }

    const index = this.model.body.indexOf(row);
    if (index !== -1) {
      this.model.body.splice(index, 1);
      this.modelChanged.emit(this.model);
    }
  }

  onCellEdit(i: number, j: number) {
    this.modelChanged.emit(this.model)
  }

  trackByI(index: number, el: any): number {
    return index;
  }

  trackByJ(index: number, el: any): number {
    return index;
  }

  onSwitchRows(firstIdx: number, secondIdx: number) {
    // do nothing if not need
    if (firstIdx < 0 || secondIdx < 0 || secondIdx > this.model.body.length - 1) {
      return;
    }
    // swap two array elements
    [this.model.body[firstIdx], this.model.body[secondIdx]] = [this.model.body[secondIdx], this.model.body[firstIdx]];
  }

  onCopyRow(srcIdx: number) {
    let src = [...this.model.body[srcIdx]];
    for (let i = 0; i < src.length; i++) {
      src[i] = `*${src[i]}`;
    }
    this.model.body.splice(srcIdx + 1, 0, src);
  }
}
