import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ILabel } from '../../../models/label';
import { LabelsService } from '../../../services/labels.service';

@Component({
  selector: 'app-label-widget',
  templateUrl: './label-widget.component.html',
  styleUrls: ['./label-widget.component.scss'],
})
export class LabelWidgetComponent implements OnInit {
  loading = false;
  allLabels: Item[];

  @Input() labels = [];
  @Output() changedLabel = new EventEmitter<Item>();
  @Output() addLabel = new EventEmitter<Item>();
  @Output() removeLabel = new EventEmitter<Item>();
  search = '';
  constructor(public labelsService: LabelsService) {}

  ngOnInit(): void {
    this.loading = true;
    this.getItems();
  }

  getItems() {
    this.labelsService.getAll(this.search).subscribe({
      next: (labels) => {
        this.allLabels = labels as Item[];
        this.loading = false;
      },
    });
  }

  onCheck(item: Item, checked: boolean) {
    item.checked = checked;
    this.changedLabel.emit(item);
    if (item.checked) {
      this.addLabel.emit(item);
    } else if (!item.checked) {
      this.removeLabel.emit(item);
    }
  }

  isChecked(item: Item) {
    return this.labels.some((label) => label.id === item.id);
  }

  onSearchTextChanged(text: string) {
    this.search = text;
    this.getItems();
  }
}

interface Item extends ILabel {
  checked: boolean;
}
