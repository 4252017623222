import {
  Component,
  EventEmitter, HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {FillData} from '../../../interfaces/FillData';

@Component({
  selector: 'app-schema-filler',
  templateUrl: './schema-filler.component.html',
  styleUrls: ['./schema-filler.component.scss'],
})
export class SchemaFillerComponent {
  @Input() fillData: FillData;
  @Output() onChangedData = new EventEmitter();

  activeTab: string = 'vars';

  styleTabContent = {
    height: '200px',
    overflowY: 'scroll'
  };

  ngOnInit() {
    // triggering first resize event
    window.dispatchEvent(new Event('resize'));
  }

  emitUpdatesData() {
    this.onChangedData.emit(this.fillData);
  }

  switchTab(tab: string) {
    this.activeTab = tab;
  }

  onTableModelChanged(tableModel: any, idx: number) {
    this.fillData.tables[idx] = tableModel;
    this.emitUpdatesData();
  }

  onImageModelChanged(imageModel: any, idx: number) {
    this.fillData.images[idx] = imageModel;
    this.emitUpdatesData();
  }

  @HostListener('window:resize', ['$event.target'])
  onResize(target) {
    // console.log('win resize', target.innerHeight);
    const offsetHeight = 260;
    this.styleTabContent.height = `${Number(target.innerHeight) - offsetHeight}px`;
  }
}
