<div class="wrapper h-auto">
  <form
    class="row g-3 h-auto"
    [formGroup]="form"
    novalidate
    (ngSubmit)="onSubmit()"
  >
    <div class="container">
      <div class="row">
        <div class="col col-8">
          <div *ngIf="!fillSchema" class='alert alert-warning' role="alert">
            Для заполнения данных, необходимо выбрать шаблон
          </div>

          <div *ngIf="fillSchema">
            <span
              [class.hide]="sourceDocumentTogglerEnabled"
              (click)="toggleSourceDocument()"
              class="custom-label"
              >Режим структуры документа</span
            >
            <span
              [class.hide]="!sourceDocumentTogglerEnabled"
              (click)="toggleSourceDocument()"
              class="custom-label"
              >Режим мастера документа</span
            >
          </div>
          <app-schema-filler
            class="col col-8"
            [class.hide]="sourceDocumentTogglerEnabled"
            *ngIf="fillSchema || fillDataText === jsonErrorText"
            style="overflow: auto"
            [fillData]="fillData"
            (onChangedData)="onSchemaFillerDataChanged($event)"
          >
          </app-schema-filler>
          <div *ngIf="fillSchema">
            <div class="form-floating">
              <textarea
                [ngStyle]="styleTextarea"
                [class.hide]="!sourceDocumentTogglerEnabled"
                [(ngModel)]="fillDataText"
                formControlName="fillData"
                (keyup)="onInputJsonChanged()"
                (change)="onInputJsonChanged()"
                type="text"
                class="form-control"
                id="fillDataInput"
                placeholder="Введите заголовок"
              >
              </textarea>
              <div
                class="invalid-feedback d-block"
                *ngIf="fillDataIsInvalidJsonText.length"
              >
                {{ fillDataIsInvalidJsonText }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-floating col d-flex flex-column gap-2">
          <input
            formControlName="name"
            [class.is-valid]="nameIsValidText.length"
            [class.is-invalid]="nameIsInvalidText.length"
            (keyup)="validate()"
            (mouseout)="validate()"
            (blur)="validate()"
            type="text"
            class="form-control"
            id="nameInput"
            placeholder="Введите заголовок"
          />
          <label for="nameInput">Название документа</label>
          <div class="valid-feedback" *ngIf="nameIsValidText.length">
            {{ nameIsValidText }}
          </div>
          <div class="invalid-feedback" *ngIf="nameIsInvalidText.length">
            {{ nameIsInvalidText }}
          </div>
          <div class="form-floating">
            <select
              (change)="onTemplateChange()"
              formControlName="template"
              class="form-select"
              id="templatesSelect"
              aria-label="Templates select"
            >
              <option selected value="">Выберите шаблон</option>
              <option
                *ngFor="let template of templates"
                ngValue="{{ template.id }}"
              >
                {{ template.name }}
              </option>
            </select>
            <label for="templatesSelect">Шаблон</label>
            <div
              class="invalid-feedback d-block"
              *ngIf="templateNotSelectedText.length"
            >
              {{ templateNotSelectedText }}
            </div>
          </div>
          <div [hidden]="false" class="form-floating">
            <textarea
              formControlName="description"
              (keyup)="validate()"
              type="text"
              class="form-control"
              style="height: 15vh"
              id="descriptionInput"
              placeholder="Введите заголовок"
            >
            </textarea>
            <label for="descriptionInput">Описание</label>
          </div>

          <app-label-widget
            *ngIf="item?.labels"
            class="w-auto"
            [labels]="item.labels"
            (addLabel)="addLabel($event)"
            (removeLabel)="removeLabel($event)"
          ></app-label-widget>
        </div>
      </div>
    </div>
    <div class="form-floating">
      <button [disabled]="!formIsValid" type="submit" class="btn btn-primary">
        {{ submitBtnText }}
      </button>
    </div>
  </form>
</div>
