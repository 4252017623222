<div class="wrapper">

  <form class="row g-3" [formGroup]="form" novalidate
        (ngSubmit)="onSubmit()">

    <div class="form-floating mb-3" style="height: 80px">
      <input formControlName="name"
             [class.is-valid]="nameIsValidText.length"
             [class.is-invalid]="nameIsInvalidText.length"
             (keyup)="validate()"
             type="text" class="form-control" id="nameInput" placeholder="Введите заголовок">
      <label for="nameInput">Заголовок страницы</label>
      <div class="valid-feedback" *ngIf="nameIsValidText.length">
        {{nameIsValidText}}
      </div>
      <div class="invalid-feedback" *ngIf="nameIsInvalidText.length">
        {{nameIsInvalidText}}
      </div>
    </div>

    <div [class.hide]="!showFileDropZone" style="height: 230px">
      <ngx-file-drop
        dropZoneClassName="drop-zone"
        contentClassName="content-zone"
        browseBtnClassName="browse-button"
        dropZoneLabel="Перетащите файл сюда"
        [multiple]="false"
        (onFileDrop)="fileDrop($event)"
        (onFileOver)="fileOver($event)"
        (onFileLeave)="fileLeave($event)">
        <ng-template
          ngx-file-drop-content-tmp
          let-openFileSelector="openFileSelector">
          <div class="inner-block">
            <span>Перетащите сюда файл шаблона или </span>&nbsp;<a class="link" (click)="openFileSelector()">загрузите с
            компьютера</a>
          </div>
        </ng-template>
      </ngx-file-drop>

      <div style="margin-top: 15px">
        <div class="alert alert-info" [innerHTML]="fileIsValidText" *ngIf="fileIsValidText.length"></div>
        <div class="alert alert-danger" [innerHTML]="fileIsInvalidText" *ngIf="fileIsInvalidText.length"></div>
      </div>
    </div>

    <div [class.hide]="!(cloneById || editId)" class="form-check form-switch">
      <input formControlName="copyTemplate" (change)="validate()"
             class="form-check-input" type="checkbox" role="switch" id="copyTemplateInput"
             checked>
      <label class="form-check-label" for="copyTemplateInput" *ngIf="editId">Не менять файл шаблона</label>
      <label class="form-check-label" for="copyTemplateInput" *ngIf="cloneById">Также скопировать и файл шаблона</label>
    </div>

    <div class="form-floating mb-3">
      <button [disabled]="!formIsValid" type="submit"
              class="btn btn-primary">{{submitBtnText}}</button>
    </div>

  </form>

</div>
