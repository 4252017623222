import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class BackupService {
  api = environment.apiBaseURL;

  constructor(private http: HttpClient) {}

  export() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    return this.http.get(`${this.api}/backup/export`, {
      responseType: 'blob',
      headers,
      observe: 'response',
    });
  }

  import(file: File) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(`${this.api}/backup/import`, formData, {
      headers,
    });
  }

  clear() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    return this.http.delete(`${this.api}/backup/clear`, { headers });
  }
}
