<div class="wrapper-out">
  <div class="wrapper-in">
    <div class="col-sm-12">
      <h1>Регистрация</h1>
    </div>
    <div [class.hide]="attemptFailed || inProgress">
      <form class="row g-3" [formGroup]="form" novalidate
            (ngSubmit)="onSubmit()">
        <div class="col-sm-12">
          <label for="inputEmail" class="form-label">Электронная почта</label>
          <input type="email" class="form-control" id="inputEmail" formControlName="email">
        </div>
        <div class="col-sm-12">
          <label for="inputPassword" class="form-label">Пароль</label>
          <input type="password" class="form-control" id="inputPassword" formControlName="password">
        </div>
        <div class="col-sm-12">
          <label for="inputPassword2" class="form-label">Пароль ещё раз</label>
          <input type="password" class="form-control" id="inputPassword2" formControlName="password2">
        </div>
        <div *ngIf='form.errors?.["notSamePasswords"] && form.controls.password2.touched' class='text-danger'>Пароли не совпадают</div>
        <div class="col-12">
          <button [disabled]="form.invalid" type="submit" class="btn btn-primary">Зарегистрироваться</button>
        </div>
      </form>
    </div>

    <ng-container *ngIf="inProgress">
      <div class="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="attemptFailed">
      <div class="text-center">
        <div class="alert alert-warning d-flex align-items-center" role="alert">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="svg-min-w bi bi-info-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
          </svg>
          <div class="align-left">
            {{warningText}}
          </div>
        </div>
      </div>
    </ng-container>

  </div>
</div>

