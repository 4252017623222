export interface IUser {
  id: string;
  login: string;
  isActive: boolean;
  roles?: IRole[];
}

export interface IRole {
  id: string;
  value: Role;
  description: string;
}

export enum Roles {
  ADMIN = 'admin',
  USER = 'user',
  UNKNOWN = 'unknown',
}

export type Role = `${Roles}`;
