import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminconsoleService } from '../../services/adminconsole.service';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { IUser } from '../../models/user';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  constructor(private router: Router, public userService: UserService) {}

  ngOnInit(): void {}

  logout() {
    localStorage.removeItem('jwt');
    this.router.navigate(['signin']);
    this.userService.roles.includes('admin');
  }

  protected readonly localStorage = localStorage;
  protected readonly JSON = JSON;
}
