import { Component, OnInit } from '@angular/core';
import { IUser } from '../../models/user';
import { Router } from '@angular/router';
import { StatusBarService } from '../../services/status-bar.service';
import { UserService } from '../../services/user.service';
import { forkJoin } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss'],
})
export class UsersPageComponent implements OnInit {
  loading = false;
  items: TableItem[];
  search = '';
  constructor(
    private router: Router,
    private statusBarService: StatusBarService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.getItems();
  }

  getItems() {
    this.userService.getAll().subscribe({
      next: (users) => {
        this.items = users as TableItem[];
        this.loading = false;
      },
    });
  }

  changeActive(item: TableItem) {
    const dto = { isActive: item.isActive };
    this.userService.update(item.id, dto).subscribe({
      error: (error: HttpErrorResponse) => {
        this.statusBarService.showError(error.error.message, error.status);
      },
    });
  }
  delete(item: TableItem) {
    this.userService.remove(item.id).subscribe({
      next: () => {
        this.statusBarService.showMessage('Успешно удалено');
        this.ngOnInit();
      },
      error: (error: HttpErrorResponse) => {
        this.statusBarService.showError(error.error.message, error.status);
      },
    });
  }

  public get atLeastOneItemSelected() {
    return this.items.some((item) => item.checked);
  }
  public get itemsAllChecked() {
    return this.items.every((item) => item.checked);
  }

  public set itemsAllChecked(checked: boolean) {
    this.items.forEach((itm) => {
      itm.checked = checked;
    });
  }

  deleteMany() {
    const deleteItems = this.items.filter((item) => item.checked);
    forkJoin(
      deleteItems.map((item) => this.userService.remove(item.id)),
    ).subscribe({
      next: () => {
        this.statusBarService.showMessage('Успешно удалено');
        this.ngOnInit();
      },
    });
  }

  onSearchTextChanged(text: string) {
    this.search = text;
    this.getItems();
  }
}

interface TableItem extends IUser {
  checked: boolean;
}
