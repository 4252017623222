import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  AbstractControl,
  ValidationErrors,
  ɵElement,
  ɵValue,
} from '@angular/forms';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  api = environment.apiBaseURL;

  constructor(private http: HttpClient, private userService: UserService) {}

  userHasAccess(): Promise<boolean> {
    return new Promise((resolve) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      });
      this.http
        .get(`${this.api}/auth/check`, { headers })
        .toPromise()
        .then((response) => {
          if (response) {
            this.userService.getMe();
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          // console.log('err?', err);
          localStorage.removeItem('jwt');
          resolve(false);
        });
    });
  }

  signin(login: string, pass: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      this.http
        .post(`${this.api}/auth/signin`, { login, password: pass }, { headers })
        .toPromise()
        .then((response) => {
          // @ts-ignore
          // console.log('R?', response.access_token);
          // @ts-ignore
          if (response.access_token) {
            // @ts-ignore
            localStorage.setItem('jwt', response.access_token);
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((err) => {
          reject(false);
        });
    });
  }

  signup(login: string, pass: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      this.http
        .post(
          `${this.api}/auth/signup`,
          { login: login, password: pass },
          { headers },
        )
        .toPromise()
        .then((response) => {
          if (response) {
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
