<div *ngIf="!loading">
  <div class="container">
    <div class="row">
      <h2 class="col-8"><i class="bi bi-bookmark me-2"></i>Метки</h2>
      <app-search-bar
        class="col-4"
        (textChanged)="onSearchTextChanged($event)"
      ></app-search-bar>
    </div>
  </div>
  <div *ngIf="!items.length" class="alert alert-info" role="alert">
    Пока не создано ни одной метки <a [routerLink]="['/labels', 'new']" class="link-primary"><i class="bi bi-patch-plus"></i>добавить</a>
  </div>
  <table
    *ngIf="items.length"
    class="table table-striped table-hover table-bordered"
  >
    <thead>
      <tr>
        <th scope="col">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              [(ngModel)]="itemsAllChecked"
            />Название метки
          </div>
        </th>
        <th scope="col">
          <div>Цвет</div>
        </th>
        <th scope="col" class="col-actions">
          <button class="btn btn-sm btn-secondary" [routerLink]="'new'">
            <i class="bi bi-file-earmark-plus"></i>
            Добавить
          </button>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let label of items">
        <td class="col-6">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              [(ngModel)]="label.checked"
            />
            <div>{{ label.value }}</div>
          </div>
        </td>
        <td class="" [style.background]="label.color">
        </td>
        <td class="col-actions">
          <button
            class="btn btn-primary btn-sm"
            [routerLink]="['/labels', label.id, 'edit']"
          >
            <i class="bi bi-pencil"></i>
            изм.
          </button>
          <button class="btn btn-danger btn-sm" (click)="delete(label)">
            <i class="bi bi-trash"></i>
            удалить
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="atLeastOneItemSelected">
    С выбранными:
    <button class="btn btn-outline-secondary btn-sm" (click)="deleteMany()">
      <i class="bi bi-trash"></i>
      удалить
    </button>
  </div>
</div>
