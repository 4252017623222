import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {NavbarComponent} from "./components/navbar/navbar.component";
import {HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TemplatesComponent} from "./pages/templates/templates.component";
import {FormTemplateComponent} from "./forms/form-template/form-template.component";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {FormSignupComponent} from "./forms/form-signup/form-signup.component";
import {FormSigninComponent} from "./forms/form-signin/form-signin.component";
import {StatusBarComponent} from "./components/status-bar/status-bar.component";
import {NgxFileDropModule} from "ngx-file-drop";
import {DocumentsComponent} from "./pages/documents/documents.component";
import {FormDocumentComponent} from "./forms/form-document/form-document.component";
import {SchemaFillerComponent} from "./components/fillers/schema-filler/schema-filler.component";
import {HelpPageComponent} from "./components/help-page/help-page.component";
import {ImageFillerComponent} from "./components/fillers/image-filler/image-filler.component";
import {TableFillerComponent} from "./components/fillers/table-filler/table-filler.component";
import {ImageRenamerPipe} from "./pipes/image-renamer.pipe";
import {TableRenamerPipe} from "./pipes/table-renamer.pipe";
import {HelpHintComponent} from './components/help-hint/help-hint.component';
import {BackupComponent} from './components/backup/backup.component';
import {SearchBarComponent} from './components/search-bar/search-bar.component';
import { ConfirmDeletingComponent } from './components/confirm-deleting/confirm-deleting.component';
import { ModalComponent } from "./components/modal/modal.component";
import { LabelsPageComponent } from './pages/labels-page/labels-page.component';
import { LabelFormComponent } from './forms/label-form/label-form.component';
import { ColorPickerModule } from "ngx-color-picker";
import { LabelWidgetComponent } from './pages/labels/label-widget/label-widget.component';
import { LabelSearchComponent } from './pages/labels/label-search/label-search.component';
import { FilterCheckedPipe } from "./pipes/filter-checked.pipe";
import { UsersPageComponent } from './pages/users-page/users-page.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    TemplatesComponent,
    FormTemplateComponent,
    DocumentsComponent,
    FormDocumentComponent,
    FormSigninComponent,
    FormSignupComponent,
    DashboardComponent,
    StatusBarComponent,
    SchemaFillerComponent,
    HelpPageComponent,
    ImageFillerComponent,
    TableFillerComponent,
    ImageRenamerPipe,
    TableRenamerPipe,
    HelpHintComponent,
    BackupComponent,
    SearchBarComponent,
    ConfirmDeletingComponent,
    ModalComponent,
    LabelsPageComponent,
    LabelFormComponent,
    LabelWidgetComponent,
    LabelSearchComponent,
    FilterCheckedPipe,
    UsersPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    ColorPickerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
