import {
  Component,
  ElementRef,
  EventEmitter, HostListener,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ILabel } from '../../../models/label';
import { LabelsService } from '../../../services/labels.service';

@Component({
  selector: 'app-label-search',
  templateUrl: './label-search.component.html',
  styleUrls: ['./label-search.component.scss'],
})
export class LabelSearchComponent implements OnInit {
  @Output() labelsSelect = new EventEmitter<ILabel[]>();
  labels: Item[] = [];
  selectMode = false;

  @ViewChild('search') selectEl: ElementRef;
  constructor(
    private labelsService: LabelsService,
  ) {
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.selectEl?.nativeElement.contains(event.target)) {
      this.selectMode = false;
    }
  }


  ngOnInit(): void {
    this.labelsService.getAll().subscribe({
      next: (labels) => {
        this.labels = labels as Item[];
      },
    });
  }

  public get selectedLabels() {
    return this.labels.filter((label) => label.checked);
  }

  public get atLeastOneSelected() {
    return !!this.selectedLabels.length;
  }

  onSelect() {
    this.labelsSelect.emit(this.selectedLabels);
  }

  unselectAll() {
    this.selectedLabels.forEach(label => label.checked = false)
    this.selectMode = false;
    this.onSelect();
  }
}

interface Item extends ILabel {
  checked: boolean;
}
