<div class="wrapper" *ngIf='isLoaded'>
  <div class="container">
    <div class="row">
      <h2 class="col-8"><i class="bi bi-list"></i> Шаблоны</h2>
      <app-search-bar class="col-4" (textChanged)="onSearchTextChanged($event)"></app-search-bar>
    </div>
  </div>

  <div *ngIf='!listNotEmpty'>
    <div class="alert alert-info" role="alert">
      Пока не создано ни одного шаблона <a [routerLink]="'/templates/new'" class="link-primary"><i class="bi bi-file-earmark-plus"></i>добавить</a>
    </div>

    <app-help-hint info='Если вы не знаете как создать шаблон, обратитесь на справочную страницу.'>
    </app-help-hint>
  </div>

  <div *ngIf="listNotEmpty && searchText && !items.length" class="alert alert-secondary">
      Шаблоны не найдены
  </div>

  <table *ngIf="items.length"
         class="table table-striped table-hover table-bordered">
    <thead>
    <tr>
      <th scope="col">
        <div class="form-check">
          <input
            [checked]="itemsAllChecked"
            (change)="onChbSelectAll()"
            class="form-check-input" type="checkbox">Название шаблона
        </div>
      </th>
      <th scope="col" class="col-actions">
        <button class="btn btn-sm btn-secondary" [routerLink]="'/templates/new'">
          <i class="bi bi-file-earmark-plus"></i>
          добавить
        </button>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of items">
      <td>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" [checked]="item.checked" (change)="onChbSelectOne(item)">
          <a class="link-primary" style="font-weight: bold;" (click)="download(item)">{{item.name}}</a>
        </div>
      </td>
      <td class="col-actions">
        <button class="btn btn-primary btn-sm" (click)="action('clone', item)">
          <i class="bi bi-files"></i>
          клон
        </button>
        <button class="btn btn-primary btn-sm" (click)="action('edit', item)">
          <i class="bi bi-pencil"></i>
          изм.
        </button>
        <button class="btn btn-danger btn-sm" (click)="action('delete', item)">
          <i class="bi bi-trash"></i>
          удалить
        </button>
      </td>
    </tr>
    </tbody>
  </table>

  <div *ngIf="atLeastOneItemSelected">
    С выбранными:
    <button class="btn btn-outline-secondary btn-sm" (click)="action('deleteMany', items)">
      <i class="bi bi-trash"></i>
      удалить
    </button>
  </div>

  <!--<pre>
    {{atLeastOneItemSelected | json}}
  </pre>

  <pre>
    {{items | json}}
  </pre>-->

  <div class="m-auto" *ngIf="pagesAll.length > 1">
    <nav aria-label="Навигация по страницам" style="align-content: center">
      <ul class="pagination justify-content-center">
        <li
          class="page-item"
          *ngFor="let routerItemIdx of pagesAll"
        >
          <a
            class="page-link" [class.active]="routerItemIdx === page"
            [routerLink]="'/templates/pages/' + routerItemIdx">
            {{routerItemIdx}}
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>



