<div class="wrapper">

  <!--<h4>TBD: image-filler component</h4>
  <pre style="height: 150px; overflow-y: auto">{{model | json}}</pre>

  <hr />-->

  <div class="image-wrapper">
    <img *ngIf="model.data" [src]="model.data" class="image">
  </div>

  <hr />

  <div [class.hide]="!isQrMode">
    <div class="mb-3">
      <label for="controlTextareaQrCodeText" class="form-label">Введите текст для создания QR-кода</label>
      <textarea [(ngModel)]="model.qrText" class="form-control" (keyup)="onTextQRChanged()" (blur)="onTextQRChanged()" id="controlTextareaQrCodeText" rows="3"></textarea>
    </div>
  </div>

  <div [class.hide]="isQrMode">
    <ngx-file-drop
      accept=".png"
      dropZoneClassName="drop-zone"
      contentClassName="content-zone"
      browseBtnClassName="browse-button"
      dropZoneLabel="Перетащите файл сюда"
      [multiple]="false"
      (onFileDrop)="fileDrop($event)"
      (onFileOver)="fileOver($event)"
      (onFileLeave)="fileLeave($event)">
      <ng-template
        ngx-file-drop-content-tmp
        let-openFileSelector="openFileSelector">
        <div class="inner-block">
          <span>Перетащите сюда PNG-файл изображения или </span>&nbsp;<a class="link" (click)="openFileSelector()">загрузите с
          компьютера</a>
        </div>
      </ng-template>
    </ngx-file-drop>
  </div>

  <hr />

  <div>
    <div class="form-check form-switch">
      <input class="form-check-input" type="checkbox" role="switch" (change)="isQrMode=!isQrMode" id="switchQrEnabled"
             [checked]="isQrMode">
      <label class="form-check-label" for="switchQrEnabled"
             title="Если этот элемент включён, то вместо загрузки изображения с компьютера пользователя изображение с QR кодом будет создано на основе текста">Использовать
        текстовое поле для создания QR кода</label>
    </div>
  </div>

</div>
