import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { StatusBarService } from '../../services/status-bar.service';
import { DocumentsService } from '../../services/documents.service';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { downloadBinary } from '../../util/files';
import { TemplatesService } from '../../services/templates.service';
import { IDocument } from '../../forms/form-document/document.interface';
import { ILabel } from '../../models/label';
import { LabelsService } from '../../services/labels.service';
import { forkJoin } from 'rxjs';
import { DocumentLabelsService } from '../../services/document-labels.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
  baseURL = '';

  orderField = 'name';
  order = 'ASC';
  page = 1;
  pagesAll = [];
  items: Array<TableItem> = [];
  itemsPerPage = environment.topPagesCount;
  itemsAllCount = 0;

  templatesListNotEmpty: boolean;
  isLoaded = false;
  searchText: string = '';
  searchLabels: ILabel[] = [];
  allLabels: ILabel[];
  listNotEmpty: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private statusBarService: StatusBarService,
    private documentsService: DocumentsService,
    private templatesService: TemplatesService,
    private labelsService: LabelsService,
    private documentLabelsService: DocumentLabelsService,
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.getItems();
      }
    });
  }

  ngOnInit(): void {
    this.getItems();
    this.baseURL = document.location.origin + '/documents/';

    this.templatesService.listNotEmpty().then((result) => {
      this.templatesListNotEmpty = result;
    });
    this.documentsService.listNotEmpty().then((result) => {
      this.listNotEmpty = result;
    });
    this.downloadLabels();
  }

  getItems() {
    this.page = Number(this.route.snapshot.paramMap.get('page')) || 1;
    const params = new HttpParams({
      fromObject: {
        page: this.page,
        limit: this.itemsPerPage,
        order: this.order,
        sortField: this.orderField,
        search: this.searchText,
        'labelIds[]': this.searchLabels.map((label) => label.id),
      },
    });
    this.documentsService.select(params).subscribe(
      (response) => {
        const items = response.data.forEach((downloadItem) => {
          downloadItem.checked = this.items.some(
            (item) => item.id === downloadItem.id && item.checked,
          );
        });

        this.items = response.data;
        this.itemsAllCount = response.pagination.itemCount;
        this.pagesAll.length = 0;
        if (
          this.page > response.pagination.pageCount &&
          response.pagination.pageCount
        ) {
          this.router.navigate([
            this.route.snapshot.url[0].path,
            'pages',
            response.pagination.pageCount,
          ]);
        }
        for (let idx = 1; idx <= response.pagination.pageCount; idx++) {
          this.pagesAll.push(idx);
        }
        this.isLoaded = true;
      },
      (err) => {
        // console.log("subscr errors?", err);
      },
    );
  }

  action(action: string, item: TableItem | Array<TableItem>) {
    switch (action) {
      case 'clone':
        this.clone(item as TableItem);
        break;
      case 'edit':
        this.edit(item as TableItem);
        break;
      case 'delete':
        if (confirm('Действительно удалить?')) {
          this.delete(item as TableItem);
        }
        break;
      case 'deleteMany':
        if (confirm('Действительно удалить выбранные?')) {
          const selectedItems = this.items.filter((itm) => {
            return itm.checked;
          });
          this.deleteMany(selectedItems);
        }
        break;
    }
  }

  clone(item: TableItem) {
    this.router.navigate([`documents/clone/${item.id}`]);
  }

  edit(item: TableItem) {
    this.router.navigate([`documents/edit/${item.id}`]);
  }

  delete(item: TableItem) {
    this.documentsService.delete(item.id).subscribe({
      next: (success) => {
        // after delete
        const pageId = this.route.snapshot.paramMap.get('page') || '';
        this.router.navigate([`documents/${pageId}`]);
        this.statusBarService.showMessage('Успешно удалено');
      },
      error: (deleteError) => {
        this.statusBarService.showError(
          `Ошибка: ${JSON.stringify(deleteError)}`,
        );
      },
    });
  }

  download(item: TableItem) {
    this.documentsService.download(item.id).subscribe({
      next: (file) => {
        downloadBinary(file.data, file.name);
      },
      error: async (err: HttpErrorResponse) => {
        const error = JSON.parse(await err.error.text());

        this.statusBarService.showError(`Ошибка: ${error.message}`, err.status);
      },
    });
  }

  downloadTemplate(templateId: string) {
    this.templatesService.download(templateId).subscribe({
      next: (file) => {
        downloadBinary(file.data, file.name);
      },
      error: async (err: HttpErrorResponse) => {
        const error = JSON.parse(await err.error.text());

        this.statusBarService.showError(`Ошибка: ${error.message}`, err.status);
      },
    });
  }

  deleteMany(items: Array<TableItem>) {
    this.documentsService.deleteMany(items).subscribe({
      next: (success) => {
        // after delete
        const pageId = this.route.snapshot.paramMap.get('page') || '';
        this.router.navigate([`documents/${pageId}`]);
        this.statusBarService.showMessage('Успешно удалено');
      },
      error: (deleteError) => {
        this.statusBarService.showError(
          `Ошибка: ${JSON.stringify(deleteError)}`,
        );
      },
    });
  }

  get jwt() {
    return localStorage.getItem('jwt');
  }

  onSearchTextChanged(searchText: string) {
    this.searchText = searchText;
    this.getItems();
  }

  onLabelsChanged(labels: ILabel[]) {
    this.searchLabels = labels;
    this.getItems();
  }

  public get atLeastOneItemSelected() {
    return this.items.some((item) => item.checked);
  }
  public get itemsAllChecked() {
    return this.items.every((item) => item.checked);
  }

  public set itemsAllChecked(checked: boolean) {
    this.items.forEach((itm) => {
      itm.checked = checked;
    });
  }
  public get checkedItems() {
    return this.items.filter((item) => item.checked);
  }

  downloadLabels() {
    this.labelsService.getAll().subscribe({
      next: (labels) => {
        this.allLabels = labels;
      },
    });
  }

  public get checkedSameLabels() {
    return this.allLabels.filter((label) =>
      this.checkedItems.every((item) =>
        item.labels.some((itemLabel) => itemLabel.id === label.id),
      ),
    );
  }

  onAddLabel(label: ILabel) {
    forkJoin(
      this.checkedItems.map((item) =>
        this.documentLabelsService.addLabel(item.id, label.id),
      ),
    ).subscribe({
      next: () => {
        this.ngOnInit();
      },
    });
  }

  onRemoveLabel(label: ILabel) {
    forkJoin(
      this.checkedItems.map((item) =>
        this.documentLabelsService.removeLabel(item.id, label.id),
      ),
    ).subscribe({
      next: () => {
        this.ngOnInit();
      },
    });
  }
}

interface TableItem extends IDocument {
  checked: boolean;
}
