import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ILabel } from '../models/label';
import { map, tap } from 'rxjs';
import { CreateLabelDto, UpdateLabelDto } from '../dto/labels/create-label.dto';
import { IDocument } from '../forms/form-document/document.interface';

@Injectable({
  providedIn: 'root',
})
export class DocumentLabelsService {
  document: IDocument;
  api = environment.apiBaseURL;

  constructor(private http: HttpClient) {}

  addLabel(documentId: string, labelId: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    return this.http
      .post<IDocument>(
        `${this.api}/documents/${documentId}/labels`,
        {
          labelId,
        },
        {
          headers,
        },
      )
      .pipe(
        map((resBody) => resBody.labels),
      );
  }

  setLabels(documentId: string, labelIds: string[]) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    return this.http
      .put<IDocument>(
        `${this.api}/documents/${documentId}/labels`,
        {
          labelIds,
        },
        {
          headers,
        },
      )
      .pipe(
        map((resBody) => resBody.labels),
      );
  }


  removeLabel(documentId: string, labelId: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    return this.http
      .delete<IDocument>(
        `${this.api}/documents/${documentId}/labels/${labelId}`,
        {
          headers,
        },
      )
      .pipe(
        map((resBody) => resBody.labels),
      );
  }
}
