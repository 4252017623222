<div class="wrapper position-relative" #search>
  <div
    *ngFor="let label of labels | filterChecked"
    [class.background]="label.color"
  >
    {{ label.value }}
  </div>
  <input
    [(ngModel)]="selectMode"
    type="checkbox"
    class="btn-check"
    id="btn-check-2-outlined"
    autocomplete="off"
  />
  <label
    [ngClass]="
      atLeastOneSelected ? 'btn-outline-success' : 'btn-outline-primary'
    "
    class="btn"
    for="btn-check-2-outlined"
    >Фильтр по меткам <i class="bi" [ngClass]="selectMode ? 'bi-caret-down' : 'bi-caret-up'"></i></label
  ><br />

  <div *ngIf="selectMode" class="position-absolute select mt-1">
    <div class="border border-5 p-3 rounded">
      <div class="overflow-auto" [style.max-height]="'16rem'">
        <div
          *ngFor="let label of labels"
          class="form-check check"
          [style.height]="'2rem'"
        >
          <input
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="label.checked"
            (ngModelChange)="onSelect()"
            id="{{ label.id }}"
          />
          <label class="form-check-label" for="{{ label.id }}">
            {{ label.value }}
          </label>
          <div
            class="color rounded ms-auto m-2"
            [style.background]="label.color"
          ></div>
        </div>
      </div>
      <hr/>
      <button class="btn btn-danger" type="button" [disabled]='!atLeastOneSelected' (click)='unselectAll()'>
        Сбросить все
      </button>
    </div>
  </div>
</div>
