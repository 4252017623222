import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { CreateTemplateDto } from '../../dto/create-template.dto';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusBarService } from '../../services/status-bar.service';
import { DocumentsService } from '../../services/documents.service';
import { CreateDocumentCopyDto } from '../../dto/create-document-copy.dto';
import { CreateDocumentDto } from '../../dto/documents/dto/create-document.dto';
import { UpdateDocumentDto } from '../../dto/documents/dto/update-document.dto';
import { TemplatesService } from '../../services/templates.service';
import { FillData } from '../../interfaces/FillData';
import { isJSON } from '../../util';
import { isJsonValidator } from '../../shared/is-json.directive';
import { FillSchema } from '../../interfaces/FillSchema';
import * as moment from 'moment';
import { ILabel } from '../../models/label';
import { DocumentLabelsService } from '../../services/document-labels.service';
import { IDocument } from './document.interface';

const BTN_TEXT_CREATE = 'Создать';
const BTN_TEXT_CREATE_BY_CP = 'Создать (на базе существующей)';
const BTN_TEXT_EDIT = 'Изменить';
const BTN_TEXT_AWAIT = 'Пожалуйста, подождите...';

@Component({
  selector: 'app-form-document',
  templateUrl: './form-document.component.html',
  styleUrls: ['./form-document.component.scss'],
})
export class FormDocumentComponent implements OnInit {
  sourceDocumentTogglerEnabled: boolean = false;

  form = this.fb.group({
    name: ['', [Validators.required]],
    copyTemplate: [true, []],
    fillData: ['', [isJsonValidator]],
    description: ['', []],
    template: [null, [Validators.required]],
  });

  formIsValid = true;
  validationTimeoutPointer = null;

  nameIsValidText = '';
  nameIsInvalidText = '';

  fillDataIsValidJson = '';
  fillDataIsInvalidJsonText = '';

  templateNotSelectedText = '';

  templates: any;

  styleTextarea = {
    height: '200px',
    overflowY: 'scroll',
  };

  submitBtnText = '';
  fillDataText: string;
  fillSchema?: FillSchema;
  fillData?: FillData;
  labels: ILabel[] = [];
  item?: IDocument = { labels: [] } as IDocument;

  public editId: string | boolean = false;
  public cloneById: string | boolean = false;
  jsonErrorText = `{"ошибка": "Некорректный JSON. Попробуйте вставить другие данные"}`;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private documentsService: DocumentsService,
    public templatesService: TemplatesService,
    private statusBarService: StatusBarService,
    private documentLabelsService: DocumentLabelsService,
    private router: Router,
  ) {}

  async ngOnInit() {
    // triggering first resize event
    window.dispatchEvent(new Event('resize'));

    this.form.controls.template.valueChanges.subscribe({
      next: () => this.onTemplateChange(),
    });

    this.editId = this.route.snapshot.paramMap.get('editId') || false;
    this.cloneById = this.route.snapshot.paramMap.get('cloneId') || false;

    if (this.cloneById) {
      this.submitBtnText = BTN_TEXT_CREATE_BY_CP;
      this.fillFormByPageId(this.cloneById, true);
    } else if (this.editId) {
      this.submitBtnText = BTN_TEXT_EDIT;
      this.fillFormByPageId(this.editId);
    } else {
      this.submitBtnText = BTN_TEXT_CREATE;
      try {
        // attempt to fill the form

        // @ts-ignore
        const savedForm: CreateTemplateDto = JSON.parse(
          localStorage.getItem('template-new-save'),
        );

        if (savedForm) {
          Object.keys(savedForm).forEach((key) => {
            this.form.controls[key].setValue(savedForm[key]);
          });
        }
      } catch (e) {
        // do not fill the form if error
      }
    }

    setTimeout(() => {
      // this.validateAlias();
      this.validate();
    });

    this.templatesService.getTemplates().subscribe((templates) => {
      this.templates = templates;
    });
  }

  async updateFillSchema() {
    if (!this.form.value.template) {
      return;
    }
    this.fillSchema = await this.templatesService.getFillSchema(
      this.form.value.template,
    );
    await this.initFillData();
  }

  async updateFillDataText() {
    try {
      this.fillDataText = JSON.stringify(this.fillData, null, '\t');
    } catch (e) {}
  }

  onTemplateChange() {
    setTimeout(async () => {
      await this.updateFillSchema();
    }, 100);
  }

  validate() {
    // @ts-ignore
    clearTimeout(this.validationTimeoutPointer);

    // @ts-ignore
    this.validationTimeoutPointer = setTimeout(async () => {
      // validate name
      if (!this.form.value.name?.length) {
        this.nameIsInvalidText = 'Необходимо заполнить';
      } else {
        this.nameIsInvalidText = '';
      }

      if (!isJSON(this.form.value.fillData)) {
        this.fillDataIsInvalidJsonText = 'Некорректная структура документа';
      } else {
        this.fillDataIsInvalidJsonText = '';
      }

      if (!this.form.value.template) {
        this.templateNotSelectedText = 'Выберите шаблон';
      } else {
        this.templateNotSelectedText = '';
      }
      this.formIsValid = this.form.valid;
    }, environment.validationTimeout);
  }

  onSubmit() {
    if (this.formIsValid) {
      this.submitBtnText = BTN_TEXT_AWAIT;
      this.formIsValid = false;
      if (this.cloneById) {
        this.createNew();
        // this.createClone(this.cloneById as string);
      } else if (this.editId) {
        this.editById(String(this.editId));
      } else {
        this.createNew();
      }
    }
  }

  // ok
  private createNew() {
    const createDocumentDto: CreateDocumentDto = {
      name: String(this.form.value.name),
      description: this.form.value.description,
      fillData: JSON.parse(this.form.value.fillData) as FillData,
      templateId: this.form.value.template as string,
      labelIds: this.item.labels.map((label) => label.id),
    };

    localStorage.setItem(
      'document-new-save',
      JSON.stringify(createDocumentDto),
    );

    this.documentsService
      .create(createDocumentDto)
      .then((created) => {
        this.router.navigate(['documents']);
        localStorage.removeItem('document-new-save');
        this.statusBarService.showMessage('Успешно добавлено!');
      })
      .catch((creationError) => {
        this.statusBarService.showError(
          `Ошибка: ${JSON.stringify(creationError)}`,
        );
        this.submitBtnText = BTN_TEXT_CREATE;
        this.validate();
      });
  }

  private async createClone(id: string) {
    const createDocumentCopyDto: CreateDocumentCopyDto = {
      originalId: id,
      name: this.form.controls.name.value,
      templateId: this.form.controls.template.value,
    };

    // if (!this.form.value.copyTemplate) {
    //   template.data = {document: this.document, path: this.files[0].relatev
    //   ivePath};
    // }

    localStorage.setItem('document-new-save', JSON.stringify(document));

    try {
      await this.documentsService.createClone(createDocumentCopyDto);
      await this.router.navigate(['documents']);
      localStorage.removeItem('template-new-save');
      this.statusBarService.showMessage('Успешно добавлено!');
    } catch (creationError) {
      this.statusBarService.showError(
        `Ошибка: ${JSON.stringify(creationError)}`,
      );
      this.submitBtnText = BTN_TEXT_CREATE_BY_CP;
      this.validate();
    }
  }

  private editById(id: string) {
    const updateDocumentDto: UpdateDocumentDto = {
      id: id,
      name: this.form.value.name,
      templateId: this.form.value.template,
      description: this.form.value.description,
      fillData: JSON.parse(this.form.value.fillData),
      labelIds: this.item.labels.map((label) => label.id),
    };

    this.documentsService.update(updateDocumentDto).subscribe({
      next: (updated) => {
        this.router.navigate(['documents']);
        this.statusBarService.showMessage('Успешно изменено!');
      },
      error: (updateError) => {
        this.statusBarService.showError(
          `Ошибка: ${JSON.stringify(updateError)}`,
        );
        this.submitBtnText = BTN_TEXT_EDIT;
        this.validate();
      },
    });
  }

  // ok
  private fillFormByPageId(id: string, cloneMode = false) {
    this.documentsService.getDocumentById(id).subscribe({
      next: (document: IDocument) => {
        // set name
        this.form.controls.name.setValue(
          document.name +
            (cloneMode
              ? ` Копия ${moment().format('YYYY-MM-DD HH:mm:ss')}`
              : ''),
        );
        this.form.controls.description.setValue(document.description);
        this.item = document;

        // set template
        this.templatesService.getTemplateById(document.templateId).subscribe({
          next: async (value: any) => {
            // this.template = value;
            this.form.controls.template.setValue(value.id);

            // set up this.fillData
            await this.updateFillSchema();

            // set parameters based on received document data
            this.fillData.parameters = document.fillData.parameters;

            // set tables based on received document data
            this.fillData.tables = document.fillData.tables;

            // set images based on received document data
            this.fillData.images = document.fillData.images;
            // update json
            await this.updateFillDataText();
          },
        });
      },
      error: (gettingError) => {
        this.statusBarService.showError(`Ошибка: ${gettingError}`);
      },
    });
  }

  async onSchemaFillerDataChanged(fillData: FillData) {
    this.fillData = fillData;
    // console.log('onSchemaFillerDataChanged', this.fillData)
    await this.updateFillDataText();
  }

  async onInputJsonChanged() {
    // console.log("input JSON?", this.fillSchemaJson);
    try {
      if (isJSON(this.fillDataText)) {
        this.fillData = JSON.parse(this.fillDataText);
      }
      this.validate();
    } catch (e) {
      // this.fillSchemaJson = this.jsonErrorText;
      // this.fillSchema = JSON.parse(this.fillSchemaJson);
    }
  }

  private async initFillData() {
    // prepare schema for data management -- start
    this.fillData = this.fillData || ({} as FillData);

    if (this.fillData.parameters) {
      this.fillData.parameters.forEach((param) => {
        param.show = false;
      });
    }

    if (this.fillData.tables) {
      this.fillData.tables.forEach((table) => {
        table.show = false;
      });
    }

    if (this.fillData.images) {
      this.fillData.images.forEach((image) => {
        image.show = false;
      });
    }

    if (this.fillSchema.parameters?.length) {
      this.fillData.parameters = this.fillData.parameters || [];
      this.fillSchema.parameters.forEach((schemaParam) => {
        const sameParam = this.fillData.parameters.find(
          (dataParam) => dataParam.key === schemaParam,
        );
        if (!sameParam) {
          this.fillData.parameters.push({
            key: schemaParam,
            value: '',
            show: true,
          });
        } else {
          sameParam.show = true;
        }
      });
    }

    if (this.fillSchema.tables?.length) {
      this.fillData.tables = this.fillData.tables || [];
      this.fillSchema.tables.forEach((schemaTable) => {
        const sameTable = this.fillData.tables.find(
          (dataTable) => dataTable.name === schemaTable.name,
        );
        if (!sameTable) {
          this.fillData.tables.push({
            name: schemaTable.name,
            head: schemaTable.head,
            body: [],
            show: true,
          });
        } else {
          sameTable.show = true;
        }
      });
    }

    if (this.fillSchema.images?.length) {
      this.fillData.images = this.fillData.images || [];
      this.fillSchema.images.forEach((schemaImage) => {
        const sameImage = this.fillData.images.find(
          (dataImage) => dataImage.name === schemaImage.name,
        );
        if (!sameImage) {
          this.fillData.images.push({
            name: schemaImage.name,
            data: '',
            qrText: '',
            show: true,
          });
        } else {
          sameImage.show = true;
        }
      });
    }

    await this.updateFillDataText();
    await this.validate();
  }

  toggleSourceDocument() {
    this.sourceDocumentTogglerEnabled = !this.sourceDocumentTogglerEnabled;
  }

  addLabel(label: ILabel) {
    this.item.labels.push(label);
    // this.documentLabelsService.addLabel(this.item.id, label.id).subscribe();
  }

  removeLabel(removedLabel: ILabel) {
    this.item.labels = this.item.labels.filter((label) => removedLabel.id !== label.id);
  }

  @HostListener('window:resize', ['$event.target'])
  onResize(target) {
    // console.log('win resize', target.innerHeight);
    const offsetHeight = 197;
    this.styleTextarea.height = `${
      Number(target.innerHeight) - offsetHeight
    }px`;
  }
}
