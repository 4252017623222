import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-confirm-deleting',
  templateUrl: './confirm-deleting.component.html',
  styleUrls: ['./confirm-deleting.component.scss'],
})
export class ConfirmDeletingComponent implements OnInit {
  form = new FormGroup({
    title: new FormControl<string>('', [Validators.required, this.matches]),
  });

  @Output() confirm = new EventEmitter<void>();

  constructor(
    public modalService: ModalService,
  ) {}

  ngOnInit(): void {}

  onConfirm() {
    this.confirm.emit();
    this.modalService.close();
  }

  get title() {
    return this.form.controls.title as FormControl;
  }

  matches(control: AbstractControl){
    return control.value === 'ОЧИСТКА' ? null : {equals: true};
  }
}
