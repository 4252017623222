import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterChecked'
})
export class FilterCheckedPipe implements PipeTransform {

  transform<T extends {checked: boolean}>(values: T[], ...args: unknown[]): T[] {
    return values.filter(value => value.checked);
  }
}
