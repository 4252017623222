import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class StatusBarService {

  constructor() {
  }

  private messenger = new BehaviorSubject(null);
  public messengerObserver = this.messenger.asObservable();


  public showMessage(message: string): void {
    this.showStatusBarMessage('info', message, 200);
  }

  public showWarning(message: string): void {
    this.showStatusBarMessage('warning', message, 200);
  }

  public showError(message: string, code: number = 418): void {
    this.showStatusBarMessage('danger', message, code);
  }

  private showStatusBarMessage(type: string, text: string, code: number): void {
    if (this.isValidData(type, text, code)) {
      this.messenger.next({
        type, message: text, code
      });
    }
  }

  private isValidData(type: string, text: string, code: number): boolean {
    return ['info', 'warning', 'danger'].includes(type) && text.length && typeof code === 'number';
  }

}
