import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CreateTemplateDto } from '../dto/create-template.dto';
import { firstValueFrom, map, Observable, tap } from 'rxjs';
import { UpdateTemplateDto } from '../dto/update-template.dto';
import { FillSchema } from '../interfaces/FillSchema';
import { ResponseFile } from "../models/responseFile";

@Injectable({
  providedIn: 'root',
})
export class TemplatesService {
  api = environment.apiBaseURL;

  constructor(private http: HttpClient) {}

  getTemplates() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    return this.http.get(`${this.api}/templates/all`, { headers });
  }

  getTemplateById(id: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    return this.http.get(`${this.api}/templates/${id}`, { headers });
  }

  async getFillSchema(templateId: string) {
    // console.log(templateId);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    return firstValueFrom(
      this.http.get<FillSchema>(`${this.api}/templates/${templateId}/schema`, {
        headers,
      }),
    );
  }

  select(params: HttpParams): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    return this.http.get(`${this.api}/templates`, { headers, params });
  }

  async listNotEmpty(): Promise<boolean> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    const params = new HttpParams({
      fromObject: {
        limit: 1,
      },
    });

    return firstValueFrom(
      this.http
        .get<any>(`${this.api}/templates`, { headers, params, responseType: 'json' })
        .pipe(map((value) => !!value.data.length)),
    );
  }

  create(templateDto: CreateTemplateDto) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });

    const formData = new FormData();
    formData.append('name', templateDto.name);
    formData.append('file', templateDto.data.document, templateDto.data.path);

    return this.http
      .post(`${this.api}/templates/create`, formData, {
        headers,
        observe: 'response',
        responseType: 'json',
      })
      .toPromise();
  }

  createClone(templateDto: CreateTemplateDto, id) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });

    const body = {
      name: templateDto.name,
      copy: id,
    };

    return this.http
      .post(`${this.api}/templates/clone`, body, {
        headers,
        observe: 'response',
        responseType: 'json',
      })
      .toPromise();
  }

  update(templateDto: UpdateTemplateDto) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });

    const formData = new FormData();
    formData.append('id', templateDto.id);
    formData.append('name', templateDto.name);

    if (templateDto.data) {
      formData.append('file', templateDto.data.document, templateDto.data.path);
    }

    return this.http
      .patch(`${this.api}/templates/update`, formData, {
        headers,
        observe: 'response',
        responseType: 'json',
      })
      .toPromise();
  }

  delete(id: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    return this.http.delete(`${this.api}/templates/${id}`, { headers });
  }

  deleteMany(items: Array<any>) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });

    return new Observable((subscriber) => {
      const deletions = [];
      for (const item of items) {
        deletions.push(
          this.http
            .delete(`${this.api}/templates/${item.id}`, { headers })
            .toPromise(),
        );
      }

      Promise.all(deletions)
        .then(() => {
          subscriber.next({ success: 'success' });
          subscriber.complete();
        })
        .catch((e) => {
          subscriber.next({ error: e });
          subscriber.error(e);
        });
    });
  }

  download(templateId: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    const url = `${this.api}/templates/${templateId}/download`;
    return this.http
      .get(url, {
        responseType: 'blob',
        headers,
        observe: 'response',
      })
      .pipe(
        map((response) => {
          const contentDisposition = response.headers.get(
            'content-disposition',
          );
          const filename = contentDisposition.match(
            /filename="(?<filename>.+)"/,
          ).groups['filename'];
          return {
            name: filename,
            data: response.body,
          } as ResponseFile;
        }),
      );
  }
}
