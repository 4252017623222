<div class="wrapper">
  <h2><i class="bi bi-archive pe-2"></i>Экспорт/Импорт/Очистка</h2>
  <hr/>
  <div class="backup">
    <h3>Экспорт резервной копии</h3>
    <div>
      <button type="button" class="btn btn-primary" (click)="download()">
        Скачать резервную копию данных
      </button>
    </div>
    <hr/>

    <h3>Импорт резервной копии</h3>
    <div>
      <ngx-file-drop
        *ngIf="showFileDropZone"
        dropZoneClassName="drop-zone"
        contentClassName="content-zone"
        browseBtnClassName="browse-button"
        dropZoneLabel="Перетащите файл сюда"
        accept=".zip"
        [multiple]="false"
        (onFileDrop)="fileDrop($event)"
      >
        <ng-template
          ngx-file-drop-content-tmp
          let-openFileSelector="openFileSelector"
        >
          <div class="inner-block">
            <span>Перетащите сюда файл резервной копии или </span>&nbsp;<a
            class="link"
            (click)="openFileSelector()"
          >загрузите с компьютера</a
          >
          </div>
        </ng-template>
      </ngx-file-drop>

      <div style="margin-top: 15px">
        <div
          class="alert alert-info"
          [innerHTML]="fileIsValidText"
          *ngIf="fileIsValidText.length"
        ></div>
        <div
          class="alert alert-danger"
          [innerHTML]="fileIsInvalidText"
          *ngIf="fileIsInvalidText.length"
        ></div>
      </div>
    </div>
    <hr/>

    <h3>Очистка системы</h3>
    <div>
      <button type="button" class="btn btn-danger" (click)="openConfirmModal()">
        Удалить все данные
      </button>
    </div>
    <hr/>
  </div>

  <app-modal
    title="Очистка системы"
    *ngIf="modalService.isVisible$ | async"
  >
    <app-confirm-deleting (confirm)="clear()">
    </app-confirm-deleting>
  </app-modal>
</div>
