<div class="wrapper">

  <div *ngIf="appMode==='ADMINMENU'">
    <div class="container">
      <navbar></navbar>
    </div>
  </div>

  <div [class.outlet-wrapper]="appMode!=='CLEANPAGE'" [class.cleanpage-wrapper]="appMode==='CLEANPAGE'">
    <div class="container">
      <router-outlet class='' style='margin: 10pt 5px;'></router-outlet>
    </div>
  </div>

  <footer class="footer">
    <div class="container">
      <status-bar></status-bar>
    </div>
  </footer>
</div>
