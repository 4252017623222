import { Component, OnInit } from '@angular/core';
import { BackupService } from '../../services/backup.service';
import { downloadBinary } from '../../util/files';
import { HttpErrorResponse } from '@angular/common/http';
import { StatusBarService } from '../../services/status-bar.service';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-backup',
  templateUrl: './backup.component.html',
  styleUrls: ['./backup.component.scss'],
})
export class BackupComponent implements OnInit {
  fileIsValidText = '';
  fileIsInvalidText = '';
  showFileDropZone = true;
  constructor(
    private backupService: BackupService,
    private statusBarService: StatusBarService,
    public modalService: ModalService,
  ) {}

  ngOnInit(): void {
  }

  download() {
    this.backupService.export().subscribe({
      next: (response) => {
        const contentDisposition = response.headers.get('content-disposition');
        const filename = contentDisposition.match(/filename="(?<filename>.+)"/)
          .groups['filename'];
        downloadBinary(response.body, filename);
      },
      error: async (err: HttpErrorResponse) => {
        const error = JSON.parse(await err.error.text());

        this.statusBarService.showError(`Ошибка: ${error.message}`, err.status);
      },
    });
  }

  public async fileDrop(entries: NgxFileDropEntry[]) {
    // this.showFileDropZone = false;
    const entry = entries[0].fileEntry as FileSystemFileEntry;
    if (!entry.name.endsWith('.zip')) {
      this.fileIsInvalidText = 'Некорректный формат файла. Он должен быть zip';
      return;
    }
    const file = await new Promise<File>((resolve) => {
      entry.file((file) => {
        resolve(file);
      });
    });
    this.fileIsValidText = 'Файл успешно загружен';
    this.showFileDropZone = false;
    this.backupService.import(file).subscribe({
      next: () => {
        this.statusBarService.showMessage(
          'Резервная копия успешно восстановлена',
        );
      },
    });
  }

  openConfirmModal() {
    this.modalService.open();
  }

  clear() {
    this.backupService.clear().subscribe({
      next: (response) => {
        this.statusBarService.showMessage('Данные очищены');
      },
      error: async (err: HttpErrorResponse) => {
        const error = err.error;

        this.statusBarService.showError(`Ошибка: ${error.message}`, err.status);
      },
    });
  }
}
