<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <div class="container-fluid">
    <a class="navbar-brand" [routerLink]="'/'">DocsAutomator</a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#mainNavbar"
      aria-controls="mainNavbar"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <i class="bi bi-list"></i>
    </button>
    <div class="collapse navbar-collapse" id="mainNavbar">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item" *ngIf='userService.roles.includes("user")'>
          <a class="nav-link" [routerLink]="'/help'" role="button">
            <i class="bi bi-info-circle"></i>
            Справка
          </a>
        </li>

        <li class="nav-item" *ngIf='userService.roles.includes("user")'>
          <a class="nav-link" [routerLink]="'/templates'" role="button">
            <i class="bi bi-list"></i>
            Шаблоны
          </a>
        </li>

        <li class="nav-item" *ngIf='userService.roles.includes("user")'>
          <a class="nav-link" [routerLink]="'/documents'" role="button">
            <i class="bi bi-file-earmark"></i>
            Документы
          </a>
        </li>

        <li class="nav-item" *ngIf='userService.roles.includes("user")'>
          <a class="nav-link" [routerLink]="'/labels'" role="button">
            <i class="bi bi-bookmark"></i>
            Метки
          </a>
        </li>

        <li class="nav-item" *ngIf='userService.roles.includes("user")'>
          <a class="nav-link" [routerLink]="'/backup'" role="button">
            <i class="bi bi-archive"></i>
            Экспорт/Импорт/Очистка
          </a>
        </li>
        <li class="nav-item" *ngIf='userService.roles.includes("admin")'>
          <a class="nav-link" [routerLink]="'/users'" role="button">
            <i class="bi bi-people"></i>
            Пользователи
          </a>
        </li>
      </ul>
      <div class="navbar-text me-2" [style.color]="'white'">
        {{userService.user?.login}}
      </div>
      <button (click)="logout()" class="btn btn-secondary">Выход</button>
    </div>
  </div>
</nav>
