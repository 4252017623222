import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageRenamer'
})
export class ImageRenamerPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return value.toString().replace('_da_image_', '').split('_').join(' ');
  }

}
