<div class="wrapper">
</div>

<form [formGroup]="form" (submit)="onConfirm()">
  <p>Если вы уверены, что хотите очистить систему, напишите <b>ОЧИСТКА</b></p>
  <input
    type="text"
    class="form-control"
    placeholder="Введите слово для подтверждения здесь"
    formControlName="title"
  >

  <div class="text-danger" [ngClass]="{'invisible': !(title.errors?.['equals'] && title.touched)}">
    Некорректное слово
  </div>

  <button type="submit" class="btn btn-danger m-1" [disabled]="!form.valid">
    Подтвердить
  </button>
</form>
