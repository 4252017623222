import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ILabel } from '../models/label';
import { map, tap } from 'rxjs';
import { CreateLabelDto, UpdateLabelDto } from '../dto/labels/create-label.dto';

@Injectable({
  providedIn: 'root',
})
export class LabelsService {
  labels?: ILabel[];
  label?: ILabel;
  api = environment.apiBaseURL;

  constructor(private http: HttpClient) {}

  getAll(search = '') {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });

    const params = new HttpParams({
      fromObject: {
        search,
      },
    });
    return this.http
      .get<ILabel[]>(`${this.api}/labels`, {
        headers,
        params,
      })
      .pipe(tap((labels) => (this.labels = labels)));
  }

  isExists(value: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });

    const params = new HttpParams({
      fromObject: {
        value,
      },
    });
    return this.http
      .get<{ result: boolean }>(`${this.api}/labels/exists`, {
        headers,
        params,
      })
      .pipe(map((response) => response.result));
  }

  create(createLabelDto: CreateLabelDto) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });

    return this.http
      .post<ILabel>(`${this.api}/labels`, createLabelDto, {
        headers,
      })
      .pipe(tap((response) => this.labels.push(response)));
  }

  update(id: string, updateLabelDto: UpdateLabelDto) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });

    return this.http
      .patch<ILabel>(`${this.api}/labels/${id}`, updateLabelDto, {
        headers,
      })
      .pipe(tap((response) => this.labels.push(response)));
  }

  getOne(id: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });

    return this.http
      .get<ILabel>(`${this.api}/labels/${id}`, {
        headers,
      })
      .pipe(tap((label) => (this.label = label)));
  }

  remove(id: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });

    return this.http.delete<ILabel>(`${this.api}/labels/${id}`, {
      headers,
    });
  }
}
