<!--<h4>TBD: table-filler component</h4>
<pre style="height: 150px; overflow-y: auto">{{model | json}}</pre>-->

<div class="wrapper">

<table class="table table-bordered table-sm table-striped table-hover">
  <thead>
  <tr>
    <th scope="col" class="btn-col">&nbsp;</th>
    <th scope="col" class="header-col" *ngFor="let header of model.head">{{ header }}</th>
    <th scope="col" class="btn-col"><button type="button" class="btn btn-success btn-sm" title="Добавить" (click)="onAddRow()"><i class="bi bi-plus-lg"></i></button></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let row of model.body; let i = index; trackBy: trackByI">
    <td class="btn-col" style="width: 120px">
      <button type="button" class="btn btn-primary btn-sm" title="Скопировать строку вниз" (click)="onCopyRow(i)" style="margin-right: 5px"><i class="bi bi-files"></i></button>
      <button [disabled]="model.body.length === 1 || i === model.body.length - 1" type="button" class="btn btn-secondary btn-sm" title="Поменять местами с нижней строкой" (click)="onSwitchRows(i, i+1)" style="margin-right: 5px"><i class="bi bi-chevron-down"></i></button>
      <button [disabled]="model.body.length === 1 || i === 0" type="button" class="btn btn-secondary btn-sm" title="Поменять местами с верхней строкой" (click)="onSwitchRows(i-1, i)"><i class="bi bi-chevron-up"></i></button>
    </td>
    <td *ngFor="let cell of row; let j = index; trackBy: trackByJ">
      <textarea
        class="form-control var-titles-textarea"
        [(ngModel)]="model.body[i][j]"
        [ngModelOptions]="{ updateOn: 'blur' }"
        (blur)="onCellEdit(i, j)"
        (mouseout)="onCellEdit(i, j)"
      ></textarea>
    </td>
    <td class="btn-col">
      <button type="button" class="btn btn-danger btn-sm" title="Удалить" (click)="onDeleteRow(row)"><i class="bi bi-x-lg"></i></button>
    </td>
  </tr>
  </tbody>
</table>
</div>
