<div *ngIf="fillData" class="wrapper">

  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link" [class.active]="activeTab==='vars'" (click)="switchTab('vars')">Переменные</a>
    </li>
    <li class="nav-item" *ngIf="fillData.tables">
      <a class="nav-link" [class.active]="activeTab==='tables'" (click)="switchTab('tables')">
        Таблицы
      </a>
    </li>
    <li class="nav-item" *ngIf="fillData.images">
      <a class="nav-link" [class.active]="activeTab==='images'" (click)="switchTab('images')">
        Изображения
      </a>
    </li>
  </ul>

  <div class="tab-content" [class.hide]="activeTab!=='vars'">
    <!--<pre>{{fillDataSchema | json}}</pre>-->
    <div [ngStyle]="styleTabContent">
      <div *ngFor="let param of fillData.parameters">
        <div *ngIf='param.show' class="input-group mb-3 w-auto">
          <div class="input-group-prepend">
            <span class="input-group-text var-titles" id="inputGroup-sizing-default">{{param.key}}</span>
          </div>
          <textarea (keyup)="emitUpdatesData()" [(ngModel)]="param.value" type="text" class="form-control var-titles-textarea"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default">
        </textarea>
        </div>
      </div>
    </div>

  </div>
  <div class="tab-content" [class.hide]="activeTab!=='tables'" *ngIf="fillData.tables">
    <div [ngStyle]="styleTabContent">
      <div class="accordion" id="tables-accordion">
        <div  *ngFor="let table of fillData.tables; let idx = index">
          <div class="accordion-item" *ngIf='table.show'>
            <h2 class="accordion-header" [id]="'heading'+idx">
              <button class="accordion-button" type="button" data-bs-toggle="collapse"
                      [attr.data-bs-target]="'#collapse' + idx" aria-expanded="true"
                      [attr.aria-valuenow]="'collapse' + idx">
                {{table.name | tableRenamer}}
              </button>
            </h2>
            <div [id]="'collapse'+idx" class="accordion-collapse collapse show" [attr.aria-labelledby]="'heading'+idx"
                 data-bs-parent="#tables-accordion">
              <div class="accordion-body">
                <app-table-filler [model]="table" (modelChanged)="onTableModelChanged($event, idx)"></app-table-filler>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-content" [class.hide]="activeTab!=='images'" *ngIf="fillData.images">
    <div [ngStyle]="styleTabContent">
      <div class="accordion" id="images-accordion">
        <div class="accordion-item" *ngFor="let image of fillData.images; let idx = index">
          <h2 class="accordion-header" [id]="'heading'+idx">
            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#collapse' + idx" aria-expanded="true"
                    [attr.aria-valuenow]="'collapse' + idx">
              {{image.name | imageRenamer}}
            </button>
          </h2>
          <div [id]="'collapse'+idx" class="accordion-collapse collapse show" [attr.aria-labelledby]="'heading'+idx"
               data-bs-parent="#images-accordion">
            <div class="accordion-body">
              <app-image-filler [model]="image" (onModelChanged)="onImageModelChanged($event, idx)"></app-image-filler>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>

