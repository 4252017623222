import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IUser, Role } from '../models/user';
import { UpdateUserDto } from "../dto/users/create-user.dto";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  api = environment.apiBaseURL + "/users";
  user: IUser;

  constructor(private http: HttpClient) {
    this.getMe();
  }

  getMe() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    this.http
      .get<IUser>(`${this.api}/me`, { headers })
      .subscribe((user) => {
        localStorage.setItem('login', user.login);
        this.user = user;
      });
  }

  public get roles(): any[] {
    return this.user?.roles?.map((role) => role.value) || [];
  }

  getAll() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    return this.http.get<IUser[]>(`${this.api}`, { headers });
  }

  update(id: string, updateUserDto: UpdateUserDto) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });

    return this.http
      .patch<IUser>(`${this.api}/${id}`, updateUserDto, {
        headers,
      })
  }

  getOne(id: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });

    return this.http.get<IUser>(`${this.api}/${id}`, {
      headers,
    });
  }

  remove(id: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });

    return this.http.delete<IUser>(`${this.api}/${id}`, {
      headers,
    });
  }
}
