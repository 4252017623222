import { Component, OnInit } from '@angular/core';
import { TemplatesService } from '../../services/templates.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { StatusBarService } from '../../services/status-bar.service';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { downloadBinary } from "../../util/files";

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
})
export class TemplatesComponent implements OnInit {
  baseURL = '';

  orderField = 'name';
  orderOrigin = 'ASC';
  page = 1;
  pagesAll = [];
  items: Array<TableItem> = [];
  itemsPerPage = environment.topPagesCount;
  itemsAllCount = 0;
  itemsAllChecked = false;
  atLeastOneItemSelected = false;
  listNotEmpty: boolean;
  isLoaded = false;
  searchText: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private statusBarService: StatusBarService,
    private templatesService: TemplatesService,
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.getItems();
    // this.meta.addTag({name: 'description', content: 'Page title content'}, true);
    this.baseURL = document.location.origin + '/templates/';
    this.listNotEmpty = await this.templatesService.listNotEmpty();
    /*  setInterval(() => {
        this.statusBarService.showMessage('Сообщение info');
        this.statusBarService.showWarning('Сообщение warning');
        this.statusBarService.showError('Сообщение error');
      }, 5000);*/
  }

  getItems() {
    this.page = Number(this.route.snapshot.paramMap.get('page')) || 1;
    const params = new HttpParams({
      fromObject: {
        page: this.page,
        limit: this.itemsPerPage,
        order: this.orderOrigin,
        sortField: this.orderField,
        search: this.searchText,
      },
    });
    this.templatesService.select(params).subscribe(
      (response) => {
        this.items = response.data;
        this.itemsAllCount = response.pagination.itemCount;
        this.pagesAll.length = 0;
        if (this.page > response.pagination.pageCount && response.pagination.pageCount) {
          this.router.navigate([this.route.snapshot.url[0].path, 'pages', response.pagination.pageCount])
        }
        for (let idx = 1; idx <= response.pagination.pageCount; idx++) {
          this.pagesAll.push(idx);
        }
        this.isLoaded = true;
      },
      (err) => {
        // console.log("subscr errors?", err);
      },
    );
  }

  action(action: string, item: TableItem | Array<TableItem>) {
    switch (action) {
      case 'clone':
        this.clone(item as TableItem);
        break;
      case 'edit':
        this.edit(item as TableItem);
        break;
      case 'delete':
        if (confirm('Действительно удалить?')) {
          this.delete(item as TableItem);
        }
        break;
      case 'deleteMany':
        if (confirm('Действительно удалить выбранные?')) {
          const selectedItems = this.items.filter((itm) => {
            return itm.checked;
          });
          this.deleteMany(selectedItems);
        }
        break;
    }
    this.ngOnInit();
  }

  clone(item: TableItem) {
    this.router.navigate([`templates/clone/${item.id}`]);
  }

  edit(item: TableItem) {
    this.router.navigate([`templates/edit/${item.id}`]);
  }

  delete(item: TableItem) {
    this.templatesService.delete(item.id).subscribe(
      (success) => {
        // after delete
        const pageId = this.route.snapshot.paramMap.get('page') || '';
        this.router.navigate([`templates/${pageId}`]);
        this.statusBarService.showMessage('Успешно удалено');
      },
      (deleteError: HttpErrorResponse) => {
        this.statusBarService.showError(
          `Ошибка: ${JSON.stringify(deleteError.error.message)}`,
          deleteError.status,
        );
      },
      () => {
        this.atLeastOneItemSelected = false;
      },
    );
  }

  deleteMany(items: Array<TableItem>) {
    this.templatesService.deleteMany(items).subscribe(
      (success) => {
        // after delete
        const pageId = this.route.snapshot.paramMap.get('page') || '';
        this.router.navigate([`templates/${pageId}`]);
        this.statusBarService.showMessage('Успешно удалено');
      },
      (deleteError) => {
        this.statusBarService.showError(
          `Ошибка: ${JSON.stringify(deleteError)}`,
        );
      },
      () => {
        this.atLeastOneItemSelected = false;
      },
    );
  }

  onChbSelectAll() {
    this.itemsAllChecked = !this.itemsAllChecked;
    // console.log('onChbSelectAll', this.itemsAllChecked);
    this.items.forEach((itm) => {
      itm.checked = this.itemsAllChecked;
    });
    this.checkAtLeastOneItemSelectedFlag();
  }

  onChbSelectOne(item) {
    item.checked = !item.checked;
    this.checkAtLeastOneItemSelectedFlag();
  }

  checkAtLeastOneItemSelectedFlag() {
    this.atLeastOneItemSelected = !!this.items.filter((itm) => {
      return itm.checked;
    }).length;

    if (!this.atLeastOneItemSelected) {
      this.itemsAllChecked = this.atLeastOneItemSelected;
    }
  }

  get jwt() {
    return localStorage.getItem('jwt');
  }

  onSearchTextChanged(searchText: string) {
    this.searchText = searchText;
    this.getItems();
  }

  download(item: TableItem) {
    this.templatesService.download(item.id).subscribe({
      next: (file) => {
        downloadBinary(file.data, file.name);
      },
      error: async (err: HttpErrorResponse) => {
        const error = JSON.parse(await err.error.text());

        this.statusBarService.showError(`Ошибка: ${error.message}`, err.status);
      },
    });
  }
}

interface TableItem {
  id: string;
  name: string;
  checked: boolean;
}
