import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {GenerateSiteTaskDto} from "../dto/generate-site-task.dto";

@Injectable({
  providedIn: 'root'
})
export class AdminconsoleService {

  api = environment.apiBaseURL;

  constructor(
    private http: HttpClient
  ) {
  }

  getTasks() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("jwt")}`
    });
    return this.http.get(`${this.api}/adminconsole/get-tasks`, { headers });
  }

  setTask(generateSiteTaskDto: GenerateSiteTaskDto) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("jwt")}`
    });
    return this.http.post(`${this.api}/adminconsole/set-task`, generateSiteTaskDto, { headers });
  }

  cleanupTasks() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("jwt")}`
    });
    return this.http.post(`${this.api}/adminconsole/cleanup-tasks`, {}, { headers });
  }
}
