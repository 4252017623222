<div class="input-group mb-3">
  <input
    id="searchBar"
    type="text"
    class="form-control"
    placeholder="Поиск"
    (keyup)="onKeyup()"
    [(ngModel)]="searchText"
  />
</div>
