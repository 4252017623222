import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-hint',
  templateUrl: './help-hint.component.html',
  styleUrls: ['./help-hint.component.scss']
})
export class HelpHintComponent implements OnInit {

  @Input() info: string;
  constructor() { }

  ngOnInit(): void {
  }

}
