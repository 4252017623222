import { Component, OnInit } from '@angular/core';
import { LabelsService } from '../../services/labels.service';
import { ILabel } from '../../models/label';
import { Router } from '@angular/router';
import { StatusBarService } from '../../services/status-bar.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-labels-page',
  templateUrl: './labels-page.component.html',
  styleUrls: ['./labels-page.component.scss'],
})
export class LabelsPageComponent implements OnInit {
  loading = false;
  items: TableItem[];
  search = '';
  constructor(
    public labelsService: LabelsService,
    private router: Router,
    private statusBarService: StatusBarService,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.getItems();
  }

  getItems() {
    this.labelsService.getAll(this.search).subscribe({
      next: (labels) => {
        this.items = labels as TableItem[];
        this.loading = false;
      },
    });
  }

  action(action: string, item: TableItem) {}

  delete(item: TableItem) {
    this.labelsService.remove(item.id).subscribe({
      next: () => {
        this.statusBarService.showMessage('Успешно удалено');
        this.ngOnInit();
      },
      error: () => {
        this.statusBarService.showMessage('Произошла ошибка');
      },
    });
  }

  public get atLeastOneItemSelected() {
    return this.items.some((item) => item.checked);
  }
  public get itemsAllChecked() {
    return this.items.every((item) => item.checked);
  }

  public set itemsAllChecked(checked: boolean) {
    this.items.forEach((itm) => {
      itm.checked = checked;
    });
  }

  deleteMany() {
    const deleteItems = this.items.filter((item) => item.checked);
    forkJoin(
      deleteItems.map((item) => this.labelsService.remove(item.id)),
    ).subscribe({
      next: () => {
        this.statusBarService.showMessage('Успешно удалено');
        this.ngOnInit();
      },
    });
  }

  onSearchTextChanged(text: string) {
    this.search = text;
    this.getItems();
  }
}

interface TableItem extends ILabel {
  checked: boolean;
}
