import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'form-signup',
  templateUrl: './form-signup.component.html',
  styleUrls: ['./form-signup.component.scss'],
})
export class FormSignupComponent implements OnInit {
  formIsValid = false;
  attemptFailed = false;
  inProgress = false;
  warningText: string = '';
  form = new FormGroup({
    email: new FormControl<string>(environment.testUser, [
      Validators.required,
      Validators.email,
    ]),
    password: new FormControl<string>(environment.testPassword, [
      Validators.required,
    ]),
    password2: new FormControl<string>(environment.testPassword, {
      validators: [Validators.required],
    }),
  }, {
    validators: [this.checkSamePasswords]
  });

  constructor(
    // private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.inProgress = true;
      // @ts-ignore
      this.authService
        .signup(this.form.value.email, this.form.value.password)
        .then(() => {
          this.attemptFailed = false;
          this.router.navigate(['/signin']);
        })
        .catch((e) => {
          this.attemptFailed = true;
          this.warningText = '';
          const msg = e.error.message;
          try {
            const msgParsed = JSON.parse(msg);
            console.log('parsed', msgParsed);
            if (msgParsed.text === 'invalid password format') {
              this.warningText = [
                'Неверный формат пароля',
                ' (',
                [
                  `мин. длина: ${msgParsed.format['min-len']}`,
                  `макс. длина: ${msgParsed.format['max-len']}`,
                  `кол-во цифр: >=${msgParsed.format['digits-cnt']}`,
                  `кол-во прописных букв: >=${msgParsed.format['upper-symbols-cnt']}`,
                  `кол-во строчных букв: >=${msgParsed.format['lower-symbols-cnt']}`,
                ].join(', '),
                ')',
              ].join('');
            } else {
              this.warningText = msgParsed.text;
            }
          } catch (e) {
            if (msg === 'user already exists') {
              this.warningText =
                'Пользователь с такой электронной почтой уже существует';
            } else {
              this.warningText = msg;
            }
          }
        })
        .finally(() => {
          this.inProgress = false;
          setTimeout(() => {
            this.attemptFailed = false;
          }, 3000);
        });
    }
  }

  checkSamePasswords(group: AbstractControl) {
    const password = group.get('password').value;
    const password2 = group.get('password2').value
    return password === password2
      ? null
      : { notSamePasswords: true };
  }
}
