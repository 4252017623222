import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {NgxFileDropEntry} from "ngx-file-drop";
import {environment} from "../../../../environments/environment";
import * as qrcode from "yaqrcode";

// For some reason, Jimp attaches to self, even in Node.
// https://github.com/jimp-dev/jimp/issues/466
import * as _Jimp from 'jimp';
// @ts-ignore
const Jimp = (typeof self !== 'undefined') ? (self.Jimp || _Jimp) : _Jimp;

const EMPTY_QR_TEXT = 'Пустой QR!';

@Component({
  selector: 'app-image-filler',
  templateUrl: './image-filler.component.html',
  styleUrls: ['./image-filler.component.scss']
})
export class ImageFillerComponent implements OnInit {

  @Input() model: any;
  @Output() onModelChanged = new EventEmitter();

  isQrMode: boolean = false;

  private changeQRTextTimeoutPointer = null;

  public files: NgxFileDropEntry[] = [];
  private image = null;

  constructor() {
  }

  ngOnInit(): void {
    this.isQrMode = this.model.qrText.length;
    if (!this.model.data.length) {
      this.onTextQRChanged();
    }
  }

  public fileDrop(files: NgxFileDropEntry[]) {
    this.files = files;
    // Is it a file?
    if (this.files[0].fileEntry.isFile) {
      const fileEntry = this.files[0].fileEntry as FileSystemFileEntry;
      fileEntry.file(async (file: File) => {
        this.image = file;
        // @ts-ignore
        Jimp.read(Buffer.from((await this.toBase64(this.image)).split('base64,')[1], 'base64'), (err, img) => {
          img.resize(environment.jimpResizeW, Jimp.AUTO).getBase64(Jimp.MIME_PNG, (err, base64Image) => {
            if (!err) {
              this.model.data = base64Image;
              if (!this.isQrMode) {
                this.model.qrText = '';
              }
              this.updateModel();
            }
          });
        });

        /**
         // You could upload it like this:
         const formData = new FormData()
         formData.append('logo', file, relativePath)

         // Headers
         const headers = new HttpHeaders({
            'security-token': 'mytoken'
          })

         this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
         .subscribe(data => {
            // Sanitized logo returned from backend
          })
         **/

      });
    }
  }

  public fileOver(event: any) {
    // console.log(event);
  }

  public fileLeave(event: any) {
    // console.log(event);
  }

  private toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  private updateModel() {
    this.onModelChanged.emit(this.model);
  }

  onTextQRChanged() {
    clearTimeout(this.changeQRTextTimeoutPointer);
    this.changeQRTextTimeoutPointer = setTimeout(async () => {
      this.model.data = await this.textToQR(this.model.qrText);
      this.updateModel();
    }, 500);
  }

  private textToQR(text: string) {
    return new Promise((resolve, reject) => {
      const base64ImageGif = qrcode(text.length ? text : EMPTY_QR_TEXT, {
        size: environment.qrCodeW,
      });
      // @ts-ignore
      Jimp.read(Buffer.from(base64ImageGif.split('base64,')[1], 'base64'), (err, img) => {
        if (!text.length) {
          img = img.color([{apply: 'mix', params: ['red', 42]}]);
          this.model.qrText = EMPTY_QR_TEXT;
        }
        img.getBase64(Jimp.MIME_PNG, (err, base64ImagePNG) => {
          if (!err) {
            resolve(base64ImagePNG);
            return;
          }
          reject(err);
        });
      });
    });
  }
}
