import {Component, OnInit} from '@angular/core';
import {StatusBarService} from "../../services/status-bar.service";
import {v4 as uuidv4} from 'uuid';

@Component({
  selector: 'status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss']
})
export class StatusBarComponent implements OnInit {

  messages: Array<StBarMessage> = [];

  readonly MAX_MSG_COUNT = 2;
  readonly MSG_LIVE_TIME = 3000;

  constructor(private statusBarService: StatusBarService) {
  }

  ngOnInit(): void {
    this.statusBarService.messengerObserver.subscribe((msg) => {

      if (msg) {
        const m: StBarMessage = {...msg};
        m.id = uuidv4();

        this.messages.push(m);
        setTimeout(() => this.removeMsg(m.id), this.MSG_LIVE_TIME);

        if (this.messages.length > this.MAX_MSG_COUNT) {
          this.messages.shift();
        }
      }

    });

  }

  removeMsg(id: string) {
    this.messages = this.messages.filter(msg => {
      return msg.id !== id;
    });
  }
}

interface StBarMessage {
  id: string,
  type: string,
  message: string,
  code: number
}
