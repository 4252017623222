import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {AdminconsoleService} from "../../services/adminconsole.service";
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  items: Array<TableItem> = [];

  constructor(
    private router: Router,
    private adminconsoleService: AdminconsoleService
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {

        /*this.getTasks().subscribe(response => {
          this.items = response;
        }, err => {
          // console.log("subscr errors?", err);
        });*/
      }
    });
  }

  ngOnInit(): void {
  }

}

interface TableItem {
  id: string;
  name: string;
  description: string;
  progress: string;
  updatedAt: string;
}
