<div class="wrapper" *ngIf="isLoaded">
  <div class="container">
    <div class="row">
      <h2 class="col-8"><i class="bi bi-file-earmark"></i> Документы</h2>
      <div class="col-4 d-flex flex-column align-items-end">
        <app-search-bar
          (textChanged)="onSearchTextChanged($event)"
        ></app-search-bar>
        <app-label-search
          class="mb-3"
          (labelsSelect)="onLabelsChanged($event)"
        ></app-label-search>
      </div>
    </div>
  </div>

  <div *ngIf="templatesListNotEmpty === false">
    <app-help-hint
      info="Список шаблонов пуст. Для создания документов нужен хотя бы один шаблон."
    >
      <button type="button" class="btn btn-success" [routerLink]="'/templates'">
        Перейти на страницу страницу с шаблонами
      </button>
    </app-help-hint>
  </div>

  <div
    *ngIf="!listNotEmpty && templatesListNotEmpty"
    class="alert alert-info"
    role="alert"
  >
    Пока не создано ни одного документа
    <a [routerLink]="'/documents/new'" class="link-primary"
      ><i class="bi bi-file-earmark-plus"></i>добавить</a
    >
  </div>

  <div *ngIf="listNotEmpty && !items.length" class="alert alert-secondary">
    Документы не найдены
  </div>

  <table
    *ngIf="items.length"
    class="table table-striped table-hover table-bordered"
  >
    <thead>
      <tr>
        <th scope="col">
          <div class="form-check">
            <input
              [(ngModel)]="itemsAllChecked"
              class="form-check-input"
              type="checkbox"
            />Название документа
          </div>
        </th>
        <th scope="col">Базовый шаблон</th>
        <th scope="col">Метки</th>
        <th scope="col" class="col-actions">
          <button
            class="btn btn-sm btn-secondary"
            [routerLink]="'/documents/new'"
          >
            <i class="bi bi-file-earmark-plus"></i>
            добавить
          </button>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of items">
        <td>
          <div class="form-check d-flex">
            <input
              class="form-check-input"
              type="checkbox"
              [(ngModel)]="item.checked"
            />
            <a
              class="link-primary ps-2"
              style="font-weight: bold"
              (click)="download(item)"
            >
              {{ item.name }}
            </a>
          </div>
        </td>
        <td>
          <div class="ps-2">
            {{ item.template.name }}
          </div>
        </td>
        <td>
          <div class="d-flex ms-auto gap-1">
            <div *ngIf="!item.labels.length" class="text-center">
              Отсутствуют
            </div>
            <div
              class="label rounded p-1"
              *ngFor="let label of item.labels"
              [style.background]="label.color"
            >
              {{ label.value }}
            </div>
          </div>
        </td>
        <td class="col-actions">
          <button
            class="btn btn-primary btn-sm"
            (click)="action('clone', item)"
          >
            <i class="bi bi-files"></i>
            клон
          </button>
          <button class="btn btn-primary btn-sm" (click)="action('edit', item)">
            <i class="bi bi-pencil"></i>
            изм.
          </button>
          <button
            class="btn btn-danger btn-sm"
            (click)="action('delete', item)"
          >
            <i class="bi bi-trash"></i>
            удалить
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <div *ngIf="atLeastOneItemSelected">
    С выбранными:
    <button
      class="btn btn-outline-secondary btn-sm"
      (click)="action('deleteMany', items)"
    >
      <i class="bi bi-trash"></i>
      удалить
    </button>
    <app-label-widget
      [labels]="checkedSameLabels"
      (addLabel)="onAddLabel($event)"
      (removeLabel)="onRemoveLabel($event)"
      class="w-25"
    ></app-label-widget>
  </div>

  <div class="m-auto" *ngIf="pagesAll.length > 1">
    <nav aria-label="Навигация по страницам" style="align-content: center">
      <ul class="pagination justify-content-center">
        <li class="page-item" *ngFor="let routerItemIdx of pagesAll">
          <a
            class="page-link"
            [class.active]="routerItemIdx === page"
            [routerLink]="'/documents/pages/' + routerItemIdx"
          >
            {{ routerItemIdx }}
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>
