import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  catchError,
  firstValueFrom,
  from,
  map,
  Observable,
  of,
  tap,
  throwError,
} from 'rxjs';
import { CreateDocumentCopyDto } from '../dto/create-document-copy.dto';
import { CreateDocumentDto } from '../dto/documents/dto/create-document.dto';
import { UpdateDocumentDto } from '../dto/documents/dto/update-document.dto';
import { ResponseFile } from '../models/responseFile';
import { IDocument } from "../forms/form-document/document.interface";

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  api = environment.apiBaseURL;

  constructor(private http: HttpClient) {}

  getDocuments() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    return this.http.get(`${this.api}/documents/list`, { headers });
  }

  getDocumentById(id: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    return this.http.get(`${this.api}/documents/${id}`, { headers });
  }

  select(params: HttpParams) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    return this.http.get<any>(`${this.api}/documents`, { headers, params });
  }

  create(createDocumentDto: CreateDocumentDto) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });

    return firstValueFrom(
      this.http.post(`${this.api}/documents`, createDocumentDto, {
        headers,
        observe: 'response',
        responseType: 'json',
      }),
    );
  }

  async createClone(createDocumentCopyDto: CreateDocumentCopyDto) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });

    return firstValueFrom(
      this.http.post(`${this.api}/documents/copy`, createDocumentCopyDto, {
        headers,
        observe: 'response',
        responseType: 'json',
      }),
    );
  }

  update(updateDocumentDto: UpdateDocumentDto) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });

    return this.http.patch(`${this.api}/documents`, updateDocumentDto, {
      headers,
      observe: 'response',
      responseType: 'json',
    });
  }

  delete(id: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    return this.http.delete(`${this.api}/documents/${id}`, { headers });
  }

  deleteMany(items: Array<any>) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });

    return new Observable((subscriber) => {
      const deletions = [];
      for (const item of items) {
        deletions.push(
          this.http
            .delete(`${this.api}/documents/${item.id}`, { headers })
            .toPromise(),
        );
      }

      Promise.all(deletions)
        .then(() => {
          subscriber.next({ success: 'success' });
          subscriber.complete();
        })
        .catch((e) => {
          subscriber.next({ error: e });
          subscriber.error(e);
        });
    });
  }

  download(documentId: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    const url = `${this.api}/documents/${documentId}/download`;
    return this.http
      .get(url, {
        responseType: 'blob',
        headers,
        observe: 'response',
      })
      .pipe(
        map((response) => {
          const contentDisposition = response.headers.get(
            'content-disposition',
          );
          const filename = contentDisposition.match(
            /filename="(?<filename>.+)"/,
          ).groups['filename'];
          return {
            name: filename,
            data: response.body,
          } as ResponseFile;
        }),
      );
  }

  async listNotEmpty(): Promise<boolean> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    });
    const params = new HttpParams({
      fromObject: {
        limit: 1,
      },
    });

    return firstValueFrom(
      this.http
        .get<any>(`${this.api}/documents`, {
          headers,
          params,
          responseType: 'json',
        })
        .pipe(map((value) => !!value.data.length)),
    );
  }
}
