import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  searchText: string = '';

  @Output() textChanged = new EventEmitter<string>();
  timer: NodeJS.Timeout;
  constructor() {}

  ngOnInit(): void {
    this.onKeyup();
  }

  onKeyup() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.textChanged.emit(this.searchText), 300);
  }
}
