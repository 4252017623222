import {Component, OnInit} from "@angular/core";
import {FormBuilder, Validators} from "@angular/forms";
import {environment} from "../../../environments/environment";
import {TemplatesService} from "../../services/templates.service";
import {CreateTemplateDto} from "../../dto/create-template.dto";
import {ActivatedRoute, Router} from "@angular/router";
import {UpdateTemplateDto} from "../../dto/update-template.dto";
import {NgxFileDropEntry} from "ngx-file-drop";
import {StatusBarService} from "../../services/status-bar.service";

const BTN_TEXT_CREATE = "Создать";
const BTN_TEXT_CREATE_BY_CP = "Создать (на базе существующей)";
const BTN_TEXT_EDIT = "Изменить";
const BTN_TEXT_AWAIT = "Пожалуйста, подождите...";
const ODF_CRITERIA = 'odt';

@Component({
  selector: "app-form-template",
  templateUrl: "./form-template.component.html",
  styleUrls: ["./form-template.component.scss"]
})
export class FormTemplateComponent implements OnInit {

  form = this.fb.group({
    name: ["", [Validators.required]],
    copyTemplate: [true, []],
    file: [null, []]
  });

  formIsValid = true;
  validationTimeoutPointer = null;

  nameIsValidText = "";
  nameIsInvalidText = "";

  fileIsValidText = "";
  fileIsInvalidText = "";

  submitBtnText = "";

  public files: NgxFileDropEntry[] = [];
  private document = null;

  public editId: string | boolean = false;
  public cloneById: string | boolean = false;
  showFileDropZone: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private templatesService: TemplatesService,
    private statusBarService: StatusBarService,
    private router: Router
  ) {
  }

  ngOnInit(): void {

    this.editId = this.route.snapshot.paramMap.get("editId") || false;
    this.cloneById = this.route.snapshot.paramMap.get("cloneId") || false;

    if (this.cloneById) {
      this.submitBtnText = BTN_TEXT_CREATE_BY_CP;
      this.fillFormByPageId(this.cloneById, true);
    } else if (this.editId) {
      this.submitBtnText = BTN_TEXT_EDIT;
      this.fillFormByPageId(this.editId);
    } else {
      this.submitBtnText = BTN_TEXT_CREATE;
      try {
        // attempt to fill the form

        // @ts-ignore
        const savedForm: CreateTemplateDto = JSON.parse(localStorage.getItem("template-new-save"));

        if (savedForm) {
          Object.keys(savedForm).forEach(key => {
            // @ts-ignore
            this.form.controls[key].setValue(savedForm[key]);
          });
        }

      } catch (e) {
        // do not fill the form if error
      }
    }

    setTimeout(() => {
      // this.validateAlias();
      this.validate();
    });
  }

  validate() {

    // @ts-ignore
    clearTimeout(this.validationTimeoutPointer);

    // @ts-ignore
    this.validationTimeoutPointer = setTimeout(async () => {

      // validate name
      if (!this.form.value.name?.length) {
        this.nameIsInvalidText = "Необходимо заполнить";
      } else {
        this.nameIsInvalidText = "";
      }

      this.formIsValid = this.form.valid;

      // check file


      if (this.document) {

        const fileExt = this.document.name.split('.').pop().toLowerCase() || 'unknown';

        if (ODF_CRITERIA === fileExt) {
          this.fileIsValidText = "Выбран файл: <b>" + this.document.name + "</b> Формат файла корректен.";
          this.fileIsInvalidText = "";
        } else {
          this.fileIsValidText = "";
          this.fileIsInvalidText = "Выбран файл не верного формата (допустимы только файлы <b>.odt</b>)";
        }

      } else {
        this.fileIsValidText = "";
        this.fileIsInvalidText = "Выберите файл шаблона";
      }


      if ((this.editId || this.cloneById) && this.form.value.copyTemplate) {
        this.showFileDropZone = false;
      } else {
        this.showFileDropZone = true;
      }

      if (this.showFileDropZone) {
        this.formIsValid = this.formIsValid && this.fileIsValidText.length && !this.fileIsInvalidText.length;
      }


    }, environment.validationTimeout);

  }

  public fileDrop(files: NgxFileDropEntry[]) {
    this.files = files;
    // Is it a file?
    if (this.files[0].fileEntry.isFile) {
      const fileEntry = this.files[0].fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        this.document = file;

        if (!this.form.value.name.length) {
          this.form.controls.name.setValue(this.document.name);
        }

        this.validate();

        // Here you can links the real file
        // console.log(this.filesLogo[0].relativePath, file);

        /**
         // You could upload it like this:
         const formData = new FormData()
         formData.append('logo', file, relativePath)

         // Headers
         const headers = new HttpHeaders({
            'security-token': 'mytoken'
          })

         this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
         .subscribe(data => {
            // Sanitized logo returned from backend
          })
         **/

      });
    }
  }

  public fileOver(event: any) {
    // console.log(event);
  }

  public fileLeave(event: any) {
    // console.log(event);
  }

  onSubmit() {
    if (this.formIsValid) {
      this.submitBtnText = BTN_TEXT_AWAIT;
      this.formIsValid = false;
      if (this.cloneById) {
        this.createClone(this.cloneById as string);
      } else if (this.editId) {
        this.editById(String(this.editId));
      } else {
        this.createNew();
      }
    }
  }

  // ok
  private createNew() {

    const template: CreateTemplateDto = {
      name: String(this.form.value.name),
      data: {document: this.document, path: this.files[0].relativePath}
    };

    localStorage.setItem("template-new-save", JSON.stringify(template));

    this.templatesService.create(template).then(created => {
      this.router.navigate(["templates"]);
      localStorage.removeItem("template-new-save");
      this.statusBarService.showMessage('Успешно добавлено!');
    }).catch(creationError => {
      this.statusBarService.showError(`Ошибка: ${creationError.error.message}`);
      this.submitBtnText = BTN_TEXT_CREATE;
      this.validate();
    });

  }

  private createClone(id: string) {

    const template: CreateTemplateDto = {
      name: String(this.form.value.name),
      data: 'copy'
    };

    if (!this.form.value.copyTemplate) {
      template.data = {document: this.document, path: this.files[0].relativePath};
    }

    localStorage.setItem("template-new-save", JSON.stringify(template));

    this.templatesService.createClone(template, id).then(created => {
      this.router.navigate(["templates"]);
      localStorage.removeItem("template-new-save");
      this.statusBarService.showMessage('Успешно добавлено!');
    }).catch(creationError => {
      this.statusBarService.showError(`Ошибка: ${JSON.stringify(creationError)}`);
      this.submitBtnText = BTN_TEXT_CREATE_BY_CP;
      this.validate();
    });

  }

  // ...
  private editById(id: string) {

    const template: UpdateTemplateDto = {
      id: id,
      name: String(this.form.value.name)
    };

    if (!this.form.value.copyTemplate) {
      template.data = {document: this.document, path: this.files[0].relativePath};
    }

    this.templatesService.update(template).then(updated => {
      this.router.navigate(["templates"]);
      this.statusBarService.showMessage('Успешно изменено!');
    }).catch(updateError => {
      this.statusBarService.showError(`Ошибка: ${JSON.stringify(updateError)}`);
      this.submitBtnText = BTN_TEXT_EDIT;
      this.validate();
    });
  }

  // ok
  private fillFormByPageId(id: string, cloneMode = false) {

    this.templatesService.getTemplateById(id).subscribe(pageById => {
      const fields = ["name"];
      try {
        // attempt to fill the for
        fields.forEach(field => {

          // @ts-ignore
          let value = pageById[field];

          if (cloneMode) {
            if (field === "name") {
              value += " (clone)";
            }
          }

          // @ts-ignore
          this.form.controls[field].setValue(value);
        });

      } catch (e) {
        // do not fill the form if error
      }

    }, gettingError => {
      this.statusBarService.showError(`Ошибка: ${gettingError}`);
    });
  }

}
