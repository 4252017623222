<form [formGroup]="form" (submit)="onSubmit()" *ngIf="!loading">
  <div class="form-group w-25">
    <label for="inputTitle">Название</label>
    <input
      type="text"
      class="form-control"
      id="inputTitle"
      placeholder="Введите значение"
      formControlName="value"
    />
    <small id="emailHelp" class="form-text text-muted"
      >Оно должно быть уникальным</small
    >
    <div
      *ngIf="value.errors?.['required'] && value.touched"
      class="text-danger"
    >
      Название метки должно быть заполнено
    </div>
    <div *ngIf="value.errors?.['isExists']" class="text-danger">
      Такая метка уже существует
    </div>
  </div>
  <div class="form-group">
    <label for="textareaDescription">Описание</label>
    <textarea
      class="form-control"
      id="textareaDescription"
      rows="3"
      placeholder="Описание метки"
      formControlName="description"
    ></textarea>
  </div>

  <div class="form-group">
    <label for="textareaDescription">Цвет</label>
    <input
      class="form-control w-25"
      [style.background]="color"
      [(colorPicker)]="color"
    />
  </div>
  <button class="btn btn-primary w-25" type="submit" [disabled]="form.invalid">
    {{ actionLabelButton.get(action) }}
  </button>
</form>
