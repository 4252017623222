<div *ngIf="!loading">
  <div class="container">
    <div class="row">
      <h2 class="col-8"><i class="bi bi-bookmark me-2"></i>Метки</h2>
      <app-search-bar
        class="col-4"
        (textChanged)="onSearchTextChanged($event)"
      ></app-search-bar>
    </div>
  </div>
  <div *ngIf="!items.length" class="alert alert-info" role="alert">
    Пока не создано ни одного пользователя
    <a [routerLink]="['/labels', 'new']" class="link-primary"
    ><i class="bi bi-patch-plus"></i>добавить</a
    >
  </div>
  <table
    *ngIf="items.length"
    class="table table-striped table-hover table-bordered"
  >
    <thead>
    <tr>
      <th scope="col">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="itemsAllChecked"
          />Email пользователя
        </div>
      </th>
      <th scope="col" class="col-actions">Активен</th>
      <th scope="col" class="col-actions">
        <button
          class="btn btn-sm btn-secondary"
          [routerLink]="'/users/new'"
        >
          <i class="bi bi-file-earmark-plus"></i>
          добавить
        </button>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of items">
      <td class="col-8">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="user.checked"
          />
          <div>{{ user.login }}</div>
        </div>
      </td>
      <td class="col-1">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="user.isActive"
            (ngModelChange)="changeActive(user)"
          />
        </div>
      </td>
      <td class="col-actions">
        <button
          class="btn btn-primary btn-sm"
          [routerLink]="['/users', user.id, 'edit']"
        >
          <i class="bi bi-pencil"></i>
          изм.
        </button>
        <button class="btn btn-danger btn-sm" (click)="delete(user)">
          <i class="bi bi-trash"></i>
          удалить
        </button>
      </td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="false">
    С выбранными:
    <button class="btn btn-outline-secondary btn-sm" (click)="deleteMany()">
      <i class="bi bi-trash"></i>
      удалить
    </button>
  </div>
</div>
