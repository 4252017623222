import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {environment} from "../../../environments/environment";
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'form-signin',
  templateUrl: './form-signin.component.html',
  styleUrls: ['./form-signin.component.scss']
})
export class FormSigninComponent implements OnInit {

  formIsValid = false;
  attemptFailed = false;
  inProgress = false;
  warningText: string = '';

  form = this.fb.group({
    email: [environment.testUser, [Validators.required, Validators.email]],
    password: [environment.testPassword, Validators.required]
  });


  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router) {
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.inProgress = true;
      // @ts-ignore
      this.authService.signin(this.form.value.email, this.form.value.password).then(() => {
        this.attemptFailed = false;
          this.router.navigate(['']);
      }).catch((e) => {
        this.attemptFailed = true;
        this.warningText = 'Проверьте правильность логина и пароля';
      }).finally(() => {
        this.inProgress = false;
        setTimeout(() => {
          this.attemptFailed = false;
        }, 3000);
      });
    }
  }

}
