import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuard } from './guards/auth.guard';
import { FormTemplateComponent } from './forms/form-template/form-template.component';
import { TemplatesComponent } from './pages/templates/templates.component';
import { FormSigninComponent } from './forms/form-signin/form-signin.component';
import { LoggedInGuard } from './guards/logged-in.guard';
import { FormSignupComponent } from './forms/form-signup/form-signup.component';
import { FormDocumentComponent } from './forms/form-document/form-document.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { HelpPageComponent } from './components/help-page/help-page.component';
import { BackupComponent } from './components/backup/backup.component';
import { LabelsPageComponent } from './pages/labels-page/labels-page.component';
import { LabelFormComponent } from './forms/label-form/label-form.component';
import { UsersPageComponent } from './pages/users-page/users-page.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        title: 'Главная',
      },

      // templates
      {
        path: 'templates/new',
        component: FormTemplateComponent,
        canActivate: [AuthGuard],
        title: 'Новый шаблон',
      },
      {
        path: 'templates/pages/:page',
        component: TemplatesComponent,
        canActivate: [AuthGuard],
        title: 'Шаблоны',
      },
      {
        path: 'templates',
        component: TemplatesComponent,
        canActivate: [AuthGuard],
        title: 'Шаблоны',
      },
      {
        path: 'templates/edit/:editId',
        component: FormTemplateComponent,
        canActivate: [AuthGuard],
        title: 'Ред. шаблон',
      },
      {
        path: 'templates/clone/:cloneId',
        component: FormTemplateComponent,
        canActivate: [AuthGuard],
        title: 'Клон шаблона',
      },

      // documents
      {
        path: 'documents/new',
        component: FormDocumentComponent,
        canActivate: [AuthGuard],
        title: 'Новый документ',
      },
      {
        path: 'documents/pages/:page',
        component: DocumentsComponent,
        canActivate: [AuthGuard],
        title: 'Документы',
      },
      {
        path: 'documents',
        component: DocumentsComponent,
        canActivate: [AuthGuard],
        title: 'Документы',
      },
      {
        path: 'documents/edit/:editId',
        component: FormDocumentComponent,
        canActivate: [AuthGuard],
        title: 'Ред. документ',
      },
      {
        path: 'documents/clone/:cloneId',
        component: FormDocumentComponent,
        canActivate: [AuthGuard],
        title: 'Клон документа',
      },
      {
        path: 'help',
        component: HelpPageComponent,
        canActivate: [AuthGuard],
        title: 'Справка',
      },

      {
        path: 'backup',
        component: BackupComponent,
        canActivate: [AuthGuard],
        title: 'Экспорт/Импорт',
      },

      {
        path: 'signin',
        component: FormSigninComponent,
        canActivate: [LoggedInGuard],
        title: 'Вход',
      },
      {
        path: 'signup',
        component: FormSignupComponent,
        canActivate: [LoggedInGuard],
        title: 'Регистрация',
      },
    ],
  },
  {
    path: 'labels',
    component: LabelsPageComponent,
    canActivate: [AuthGuard],
    title: 'Метки',
  },
  {
    path: 'labels/new',
    component: LabelFormComponent,
    canActivate: [AuthGuard],
    title: 'Соз. метки',
  },
  {
    path: 'labels/:itemId',
    canActivate: [AuthGuard],
    children: [
      { path: 'edit', title: 'Ред. метки', component: LabelFormComponent },
    ],
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    title: 'Пользователи',
    component: UsersPageComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
