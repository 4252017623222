<div *ngIf='!loading' class="wrapper border border-5 p-3 rounded w-auto" >
  <h3>Выбрать метки</h3>
  <app-search-bar (textChanged)='onSearchTextChanged($event)'></app-search-bar>
  <div class="overflow-auto" [style.max-height]="'8rem'">
    <div
      *ngFor="let label of allLabels"
      class="form-check check"
      [style.height]="'2rem'"
    >
      <input
        class="form-check-input"
        type="checkbox"
        [ngModel]='isChecked(label)'
        (ngModelChange)='onCheck(label, $event)'
        id="{{ label.id }}"
      />
      <label class="form-check-label" for="{{ label.id }}">
        {{ label.value }}
      </label>
      <div
        class="color rounded ms-auto m-2"
        [style.background]="label.color"
      ></div>
    </div>
  </div>
  <hr />
  <a class="btn btn-success" type="button" [routerLink]="'/labels/new'" target="_blank">Создать новую</a>
  <a class="btn btn-primary" type="button" [routerLink]="'/labels'" target="_blank">
    Управление метками
  </a>
</div>
