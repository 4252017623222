import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Action } from '../../interfaces';
import { LabelsService } from '../../services/labels.service';
import { map, of } from 'rxjs';
import {
  CreateLabelDto,
  UpdateLabelDto,
} from '../../dto/labels/create-label.dto';
import { StatusBarService } from '../../services/status-bar.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-label-form',
  templateUrl: './label-form.component.html',
  styleUrls: ['./label-form.component.scss'],
})
export class LabelFormComponent implements OnInit {
  form = new FormGroup({
    value: new FormControl<string>('', {
      validators: [Validators.required],
      asyncValidators: [this.checkUniqueValue.bind(this)],
    }),
    description: new FormControl<string>('', []),
    color: new FormControl<string>('#ffffff', [Validators.required]),
  });

  loading = false;
  action: Action;
  itemId?: string;
  actionLabelButton = new Map<Action, string>()
    .set('new', 'Создать метку')
    .set('edit', 'Обновить метку')

  public get value() {
    return this.form.controls.value as FormControl;
  }
  public get color() {
    return this.form.controls.color.value;
  }

  public set color(value: string) {
    this.form.controls.color.setValue(value);
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private labelsService: LabelsService,
    private statusBarService: StatusBarService,
  ) {}

  ngOnInit(): void {
    const segments = this.route.snapshot.url;
    const lastSegment = segments[segments.length - 1];
    this.action = lastSegment.path as Action;
    switch (this.action) {
      case 'edit': {
        this.itemId = this.route.snapshot.paramMap.get('itemId');
        this.fillForm(this.itemId);
      }
    }
  }

  onSubmit() {
    switch (this.action) {
      case 'new': {
        this.create();
        break;
      }
      case 'edit': {
        this.update();
        break;
      }
    }
  }

  create() {
    const createLabelDto = this.form.value as CreateLabelDto;
    this.labelsService.create(createLabelDto).subscribe({
      next: () => {
        this.statusBarService.showMessage('Успешно создано');
        this.router.navigate(['labels']);
      },
      error: (error: HttpErrorResponse) => {
        this.statusBarService.showError(`Ошибка: ${JSON.stringify(error)}`);
      },
    });
  }

  update() {
    const updateLabelDto = this.form.value as UpdateLabelDto;
    this.labelsService.update(this.itemId, updateLabelDto).subscribe({
      next: () => {
        this.statusBarService.showMessage('Успешно обновлено');
        this.router.navigate(['labels']);
      },
      error: (error: HttpErrorResponse) => {
        this.statusBarService.showError(`Ошибка: ${JSON.stringify(error)}`);
      },
    });
  }

  checkUniqueValue(control: AbstractControl) {
    if (control.value === this.labelsService.label.value) {
      return of(null);
    }
    return this.labelsService
      .isExists(control.value)
      .pipe(map((result) => (result ? { isExists: true } : null)));
  }

  fillForm(id: string) {
    this.loading = true;
    this.labelsService.getOne(id).subscribe({
      next: (label) => {
        this.form.setValue({
          value: label.value,
          description: label.description,
          color: label.color || this.form.value.color,
        });
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 422) {
          this.router.navigate(['labels']);
          this.statusBarService.showError(`Метка не найдена`, error.status);
        }
      },
    });
  }
}
